@import "../utilities";

.skills-chart {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;
  gap: size(40);

  .custom-legend {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: size(16);

    .legend {
      &-item {
        cursor: pointer;

        &.disabled-legend-item {
          cursor: not-allowed;
          filter: grayscale(1);
          opacity: 0.6;

          .legend-box {
            background: $grey-92 !important;
          }
        }
      }

      &-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        @include rhythm(14, 14);

        span {
          opacity: 0.8;
        }
      }

      &-label {
        .title {
          font-weight: 600;
        }
      }
    }
  }
}
