@import "../utilities";

.first-visit-views {
  &--step--content {
    .step-content {
      &--wrapper {
        display: flex;
        flex-direction: row;
      }

      &--left {
        flex: 1 1 auto;
        padding-right: size(40);
        display: flex;
        flex-direction: column;

        h3 {
          font-family: $font-family-worksans;
          font-weight: 600;
          font-size: size(56);
          line-height: size(56);
          letter-spacing: -0.84px;
          margin: 40px 0;
        }

        p {
          font-weight: 700;
          font-size: size(32);
          line-height: size(40);
          margin-top: 0;
        }
      }

      &--right {
        flex: 0 0 352px;
        display: flex;
        align-items: center;
      }
    }

    &.check-personal-information,
    &.connect-linkedin {
      .form--container {
        background: none;
        padding: 0;
        border-radius: 0;
        margin-top: size(32);

        &--inner {
          background-color: $grey-bg;
          padding: size(24);
          border-radius: size(24);
        }

        &--header {
          display: flex;
          align-items: center;

          .logo-wrapper {
            width: 58px;
            height: 58px;
            padding: 10px 12px;
            border-radius: 58px;
            box-sizing: border-box;
            background: white;
            display: flex;
            justify-content: center;
            align-items: center;
            filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.15));
            margin-right: 1rem;

            img {
              max-width: 100%;
              max-height: 100%;
            }
          }

          .description-wrapper {
            font-size: size(18);
            font-weight: 700;
          }
        }

        .form--buttons {
          display: flex;
          gap: 1.5rem;
          justify-content: flex-end;
          margin-top: size(40);

          &.connect-linkedin {
            justify-content: space-between;
          }
        }
      }
    }
  }

  &--buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: size(30);

    &.step-0 {
      justify-content: flex-end;
    }

    &.check-information-steps {
      display: none;
    }
  }
}

.firstvisit {
  &__modal {
    border-radius: size(24);
    max-width: size(954);
    width: 100%;
    padding: size(40);

    &--small {
      border-radius: size(24);
      max-width: size(695);
      width: 100%;


      &-padding {
        padding: size(24);
      }

      .form--fields-container .form--field--wrapper .ms-Checkbox-label {
        align-items: flex-start;

        .ms-Checkbox-checkbox {
          margin-top: size(5);
        }
      }
    }

    &--label {
      padding: size(8) size(16);
      @include rhythm(16, 22);
      font-weight: 600;
      color: $white;
      border-radius: size(99);
      background: linear-gradient(90deg, #0000e6 -11.66%, #27a9a3 97.22%);
    }

    &--header {
      display: flex;
      align-items: center;
      flex-flow: row nowrap;
      justify-content: space-between;
    }

    &--columns {
      display: grid;
      grid-template-columns: 0.656fr 0.344fr;
      grid-column-gap: size(24);
    }

    &--textcols {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: size(24);
    }
  }

  &--heading {
    font-family: $font-family-worksans;
    font-weight: 600;
    @include rhythm(48, 52);
    color: $brand-10;
  }

  &__buttons {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: size(16);
    justify-content: flex-end;
  }

  &__steps {
    &--indicator {
      position: relative;
      display: inline-flex;
      align-items: center;
      flex-flow: row nowrap;
      gap: size(72);
      justify-content: flex-start;

      &:before {
        background-color: #e0e0e0;
        content: "";
        display: block;
        height: 1px;
        width: 100%;
        position: absolute;
        z-index: 0;
        top: calc(50%);
      }

      > div {
        z-index: 1;
        position: relative;
      }
    }

    &--indicator-large {
      position: relative;
      display: inline-flex;
      align-items: center;
      flex-flow: row nowrap;
      gap: size(128);
      justify-content: center;

      &:before {
        background-color: #e0e0e0;
        content: "";
        display: block;
        height: 1px;
        width: 100%;
        position: absolute;
        z-index: 0;
        top: calc(50%);
      }

      > div {
        z-index: 1;
        position: relative;
      }
    }
  }

  &__step {
    &--content {
      display: flex;
      flex-flow: row wrap;
      column-gap: size(40);
      align-items: center;

      &-col1 {
        flex: 0 0 auto;
      }

      &-col1 {
        flex: 1 0 0;
        max-width: 100%;
      }

      p span {
        color: $brand-80;
      }
    }

    &--step1,
    &--step2,
    &--step3,
    &--step4 {
      img {
        max-width: size(300);
        width: 100%;
      }
    }

    &--step3 {
      img {
        margin-right: size(8);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.14),
        0 0 0 rgba(0, 0, 0, 0.12);
        border-radius: size(24);
        max-width: size(242);
      }
    }
  }
}
