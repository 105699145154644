.text {
  &-style {
    &--Large-title {
      font-weight: 600;
      @include rhythm(40, 52);
    }

    &--Main-title,
    &--Title1 {
      font-weight: 600;
      @include rhythm(32, 40);
    }

    &--Subtitle1 {
      font-weight: 600;
      @include rhythm(20, 28);
    }

    &--Subtitle2 {
      font-weight: 600;
      @include rhythm(16, 22);
    }

    &--MobileSubtitle2 {
      font-weight: 400;
      @include rhythm(14, 20);
    }

    &--Title2 {
      font-weight: 600;
      @include rhythm(28, 36);
    }

    &--Title3 {
      font-weight: 600;
      @include rhythm(24, 32);
    }

    &--Ingress {
      @include rhythm(20, 24);
    }

    &--Body1 {
      font-family: $font-family-lato;
      color: $text-color--brand-10;
      @include rhythm(18, 24);
    }

    &--Body2 {
      font-family: $font-family-lato;
      @include rhythm(16, 22);
      font-weight: 400;
    }

    &--Body3 {
      font-family: $font-family-lato;
      @include rhythm(17, 17.5);
    }

    &--Helper {
      @include rhythm(14, 17.5, -0.21);
    }

    &--semibold {
      font-weight: 600;
    }

    &--bold {
      font-weight: 700;
    }

    &--nowrap {
      white-space: nowrap;
    }

    &--italic {
      font-style: italic;
    }

    &--field-display-label {
      font-family: $font-family-lato;
      color: $brand-80;
      @include rhythm(18, 20);
      margin-bottom: size(12);
      font-weight: 600;
    }
  }

  &-align {
    &--center {
      text-align: center;
    }
  }

  &-color {
    &--black {
      color: #000;
    }

    &--white {
      color: #fff;
    }

    &--body2 {
      color: $text-color--body2;
    }

    &--grey32 {
      color: $grey-32;
    }

    &--grey38 {
      color: $grey-38;
    }

    &--grey44 {
      color: $grey-44;
    }

    &--grey46 {
      color: $grey-46;
    }

    &--brand20 {
      color: $brand-20;
    }

    &--brand10 {
      color: $brand-10;
    }

    &--brand15 {
      color: $brand-15;
    }

    &--brand80 {
      color: $brand-80;
    }

    &--neutral-foreground {
      color: $text-color--neutral-foreground;
    }

    &--neutral-foreground-2 {
      color: $text-color--neutral-foreground-2;
    }

    &--neutral-foreground-3 {
      color: $text-color--neutral-foreground-3;
    }
  }
}

.fw {
  &-400 {
    font-weight: 400;
  }

  &-600 {
    font-weight: 600;
  }

  &-700 {
    font-weight: 700;
  }
}
