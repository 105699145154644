@import "../utilities";

.match-meter {
  margin: size(5) auto;
  width: size(108);

  &-label {
    color: #008379;
    font-family: $font-family-opensans;
    font-size: size(12);
    font-weight: 600;
    letter-spacing: 0;
    line-height: size(18.5);
    margin-bottom: size(3);

    > .match-meter-icon {
      margin-bottom: size(-3);
    }
  }

  &-slider {
    border-radius: 2px;
    background: linear-gradient(90deg, #08757A 18.61%, #19B19C 100%);
    height: size(4);
    margin: 0 auto;
    max-width: 90px;
  }
}
