@use "sass:math";

@function calculateTypeOffset($lh, $fontSize, $descenderHeightScale) {
  $lineHeightScale: $lh / $fontSize;
  @return ($lineHeightScale - 1) / 2 + $descenderHeightScale;
}

$baseFontSize: 16;
$gridRowHeight: 4;

@mixin basekick($typeSizeModifier, $typeRowSpan, $descenderHeightScale, $capHeight) {
  $fontSize: $typeSizeModifier * $baseFontSize;
  $lineHeight: $typeRowSpan * $gridRowHeight;
  $typeOffset: calculateTypeOffset($lineHeight, $fontSize, $descenderHeightScale);
  $topSpace: $lineHeight - $capHeight * $fontSize;
  $heightCorrection: 0;

  @if $topSpace > $gridRowHeight {
    $heightCorrection: $topSpace - ($topSpace % $gridRowHeight);
  }

  $preventCollapse: 1;

  font-size: #{$fontSize}px;
  line-height: #{$lineHeight}px;
  transform: translateY(#{$typeOffset}em);
  padding-top: $preventCollapse;

  &::before {
    content: "";
    margin-top: #{-($heightCorrection + $preventCollapse)}px;
    display: block;
    height: 0;
  }
}

@mixin Lato($typeSizeModifier, $typeRowSpan) {
  $latoDescenderHeightScale: 0.11;
  $latoCapHeight: 0.75;

  @include basekick($typeSizeModifier, $typeRowSpan, $latoDescenderHeightScale, $latoCapHeight);
  font-family: Lato;
}

@mixin content-container() {
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  @media all and (min-width: 1280px) {
    max-width: size(1024);
  }

  @media all and (min-width: 1680px) {
    max-width: size(1200);
  }
}

@mixin button($size: "large") {
  @include rhythm(16, 16);
  font-family: $font-family-lato;
  font-weight: 600;
  border-radius: size(99);
  padding: size(10) size(16);
  display: flex;
  //min-width: auto;
  //height: auto;
  //align-self: center;
  //text-transform: uppercase;

  @if $size == "medium" {
    @include rhythm(16, 16);
    padding: size(6) size(13) size(5);
  }

  @if $size == "small" {
    @include rhythm(12, 12);
    font-weight: 400;
    padding: size(2) size(10);
  }

  @if $size == "largeicon" {
    border-radius: 50%;
    line-height: 1;
    padding: size(9);
  }

  @if $size == "mediumicon" {
    border-radius: 50%;
    line-height: 1;
    padding: size(7);
  }

  @if $size == "smallicon" {
    border-radius: 50%;
    line-height: 1;
    padding: size(3.2);
  }

  @if $size == "showmore" {
    font-weight: 600;
    @include rhythm(16, 22);
    border-radius: size(24);
    line-height: 1;
    padding: size(4) size(8);
  }
}

@mixin button-style($style: "primary") {
  background: $button--primary--normal--bg;
  color: $button--primary--normal--text;
  border: 1px solid $button--primary--normal--bg;
  transition: all 0.15s ease-in;

  &:hover {
    background: $button--primary--hover--bg;
    color: $button--primary--hover--text;
    border: 1px solid $button--primary--hover--bg;
  }

  &:active {
    background: $button--primary--active--bg;
    border: 1px solid $button--primary--active--border;
    color: $button--primary--active--text;
  }

  @if $style == "secondary" {
    background: $button--secondary--normal--bg;
    color: white;
    border: 1px solid $button--secondary--normal--border;

    &:hover {
      background: $button--secondary--hover--bg;
      color: white;
      border: 1px solid $button--secondary--hover--border;
    }

    &:active {
      background: $button--secondary--active--bg;
      border: 1px solid $button--secondary--active--border;
      color: $button--secondary--active--text;
    }
  }

  @if $style == "outline" {
    background: $button--outline--normal--bg;
    border: 1px solid $button--outline--normal--border;
    color: $button--outline--normal--text;

    &:hover {
      background: $button--outline--hover--bg;
      border: 1px solid $button--outline--hover--border;
      color: $button--outline--hover--text;
    }

    &:active {
      background: $button--outline--active--bg;
      border: 1px solid $button--outline--active--border;
      color: $button--outline--active--text;
    }
  }

  @if $style == "showmore" {
    background: $white;
    border: none;
    box-shadow: 0 size(4) size(8) rgba(0, 0, 0, 0.14),
    0 0 size(2) rgba(0, 0, 0, 0.12);
    color: $brand-80;

    &:hover {
      background: $white;
      border: none;
      color: $brand-60;
    }

    &:active {
      background: $white;
      border: none;
      color: $brand-60;
    }
  }

  @if $style == "candy" {
    background: linear-gradient(90deg, #0000e6 -11.66%, #27a9a3 97.22%);
    border: 0px;
    color: white;

    &:hover {
      background: linear-gradient(90deg, #27a9a3 -11.66%, #0000e6 100.68%);
      border: 0px;
      color: white;
    }

    &:active {
      background: linear-gradient(90deg, #27a9a3 -11.66%, #0000e6 100.68%);
      border: 0px;
      color: white;
    }
  }
}

@mixin fsize($value) {
  font-size: math.div($value, 16) * 1rem;
}

@mixin line-height($heightValue) {
  line-height: math.div($heightValue, 16) * 1rem;
}

@mixin rhythm($sizeValue, $heightValue, $letterSpacing: null, $important: false) {
  $importantSuffix: if($important, "!important", "");

  font-size: math.div($sizeValue, 16) * 1rem #{$importantSuffix};
  line-height: math.div($heightValue, 16) * 1rem #{$importantSuffix};

  @if $letterSpacing {
    letter-spacing: math.div($letterSpacing, $sizeValue) * 1em #{$importantSuffix};
  }
}
