@import "../utilities";

.profile-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: size(24) size(16);
  text-align: center;

  &--container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }

  &--content--top {
    flex: 1;
    margin-bottom: 0;
    min-height: 124px;
  }

  &--content--middle {
    min-height: 96px;
  }

  &:hover {
    cursor: pointer;

    .profile-card-seemore-button {
      background: linear-gradient(90deg, #018785 0%, #018785 105.09%);
    }
  }

  &--avatar--wrapper {
    display: block;
    margin: auto;
    margin-top: 0;
    margin-bottom: size(10);
    height: size(64);
    width: size(64);
    box-shadow:
      0 0 2px 0 rgba(0, 0, 0, 0.12),
      0 4px 8px 0 rgba(0, 0, 0, 0.14);
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      text-align: center;
      object-fit: cover;
      color: transparent;
      text-indent: 10000px;
    }
  }

  &--text {
    &--primary {
      @extend .text-style--Subtitle2;
      @extend .text-color--brand10;
      display: block;

      &.name {
        margin-bottom: size(8);
        @extend .text-style--Body1;
      }

      &.job-title {
        margin-bottom: size(2);
      }
    }

    &--secondary {
      @extend .text-style--Body2;
      display: block;

      &.department {
        margin-bottom: size(2);
      }

      &.country {
        margin-bottom: size(2);
      }
    }
  }

  &--match-meter--container {
    margin-top: size(10);
    margin-bottom: size(16);
  }

  &--seemore--wrapper {
    display: flex;
    justify-content: center;
  }

  &:hover {
    .profile-card--seemore--button {
      background-color: $button--primary--hover--bg;
    }
  }
}
