// FONTS

$font-family-lato: "Lato", sans-serif;
$font-family-worksans: WorkSans, sans-serif;
$font-family-opensans: "Open Sans", sans-serif;

/**
COLORS
 */

// Brand colors
$brand-primary: #08757a;
$brand-active: #22b6af;
$brand-10: #020404;
$brand-15: #202637;
$brand-20: #333333;
$brand-40: #0e393c;
$brand-60: #14575b;
$brand-80: #08757a;
$brand-120: #22b6af;
$brand-140: #9ad6da;
$brand-150: #c6e9eb;
$brand-160: #f0fafa;

// Additional colors
$light-teal: #00b7c3;
$light-blue: #ddf4ff;
$darker-blue: #b9e9ff;

// Border colors
$brand-stroke: $brand-140;
$brand-stroke-hover: $brand-active;

$grey-32: #424242;
$grey-34: #4d4d4d;
$grey-38: #616161;
$grey-44: #707070;
$grey-46: #757575;
$grey-50: #808080;
$grey-62: #9e9e9e;
$grey-70: #b3b3b3;
$grey-92: #ecedf0;
$grey-94: #f3f4f7;

$grey-bg: $grey-94;
$bg-main: #eceff3;
$bg-green: #107c10;
$dark-green: #0b6a0b;
$press-green: #10666a;
$white: #ffffff;
$black: #000000;
$dark-red: #420610;
$brand-pressed: $press-green;

$bg-green-bar: #13a10e;
$bg-yellow-bar: #eaa300;

$green-light: #ebf5f5;

// TEXT COLORS
$text-color--secondary: #5e6971;
$text-color--brand-10: $brand-10;
$label-display: $grey-44;
$text-color--neutral-foreground: #242424;
$text-color--body2: $grey-44;
$text-color--neutral-foreground-2: $grey-32;
$text-color--neutral-foreground-3: $grey-38;

// LINK COLORS
$nav-link: $grey-46;
$nav-link-active: $text-color--brand-10;
$nav-link-hover: $brand-20;

// SKILL LEVEL COLORS
$skill-expertise: $brand-150;
$skill-professional: $brand-160;
$skill-basic: $white;

// LANGUAGE SKILL LEVEL COLORS
$language-native: $darker-blue;
$language-professional: $light-blue;
$language-basics: $white;

// Border colors
$border-divider: #d1d1d1;
$border-sidebar: #e0e0e0;
$border-skill: #e0e0e0;
$border-red: #d41919;

// Icons
$icon-normal: $grey-44;
$icon-active: $brand-10;
$icon-active-bg: $brand-150;

// Buttons
$button--primary--normal--bg: $brand-primary;
$button--primary--normal--text: white;
$button--primary--normal--border: $border-divider;
$button--primary--hover--bg: $brand-40;
$button--primary--hover--text: white;
$button--primary--hover--border: $border-divider;
$button--primary--active--bg: $brand-60;
$button--primary--active--text: white;
$button--primary--active--border: $brand-60;

$button--secondary--normal--bg: $brand-10;
$button--secondary--normal--border: $border-divider;
$button--secondary--normal--text: white;
$button--secondary--hover--bg: $brand-60;
$button--secondary--hover--border: $brand-60;
$button--secondary--hover--text: white;
$button--secondary--active--bg: $brand-60;
$button--secondary--active--border: $grey-70;
$button--secondary--active--text: white;

$button--outline--normal--bg: transparent;
$button--outline--normal--border: $grey-62;
$button--outline--normal--text: $brand-10;
$button--outline--hover--bg: transparent;
$button--outline--hover--border: $brand-10;
$button--outline--hover--text: $brand-10;
$button--outline--active--bg: $brand-10;
$button--outline--active--border: white;
$button--outline--active--text: white;
