@import "../utilities";

.skilltable {
  &__row {
    border-bottom: 1px solid $border-divider;
    display: flex;
    flex-direction: column;
    padding: 0;
    @include rhythm(16, 22);
    font-weight: 400;
    color: $brand-10;
    cursor: pointer;

    &.no-users {
      cursor: not-allowed;

      .ms-Button.ms-Button--icon {
        cursor: not-allowed;

        &:hover,
        &:focus {
          background: none;
        }
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &__cols {
    display: flex;
    align-items: center;
    gap: size(24);
    padding: size(12) size(16);

    &--icon {
      display: flex;
      align-items: center;
      flex-flow: row nowrap;
      gap: size(10);
      width: 20%;

      .ms-Button--icon {
        color: $brand-10;
        width: size(20);
        height: size(20);
      }
    }

    &--rate {
      width: 23.28%;
    }

    &--count {
      width: 11.5%;
    }

    &--usericons {
      width: 20.93%;

      &--wrap {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        @include rhythm(14, 16);

        .ms-Persona {
          margin-left: size(-8);
          height: size(20);
          max-width: size(20);

          .ms-Persona-imageArea {
            height: size(20);
            width: size(20);
          }

          .ms-Persona-initials {
            height: size(16);
            width: size(16);
            line-height: size(16);
            border: 2px solid $white;
          }
        }
      }
    }

    &--button {
      text-align: right;
      flex-grow: 1;
    }
  }

  &__collapse {
    //margin-top: size(12);

    &--person {
      padding: size(6) size(24) size(6) size(19);
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
      align-items: center;
      @include rhythm(16, 22);
      border-top: 1px solid $brand-150;

      &:hover {
        background: $brand-160;
      }

      .button--outline--small {
        background: $white;
      }

      .ms-Button-label {
        font-family: $font-family-lato;
        @include rhythm(12, 16);
      }

      .ms-TooltipHost {
        font-family: $font-family-lato;
        color: $brand-10;
        @include rhythm(16, 22);
      }
    }
  }
}
