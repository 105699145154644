@import "../utilities";

.user-profile-overview {
  background: rgba(256, 256, 256, 0.8);
  border-radius: size(24) size(24) 0 0;
  padding: size(24) size(56);

  &--container {
    display: flex;
    flex-direction: row;
  }

  &--content {
    &--island {
      height: 170px;
      flex: 0 0 60%;
      width: 60%;
      display: flex;
      flex-direction: row;
      align-items: center;

      .user-profile-overview--avatar {
        &--container {
          width: 170px;
          height: 170px;
          //border-radius: size(120);
          margin-right: size(24);
          position: relative;

          img {
            width: 100%;
            height: 100%;
            text-align: center;
            object-fit: cover;
            color: transparent;
            text-indent: 10000px;
            border-radius: 50%;
          }


          .button--edit-image {
            background: white;
            position: absolute;
            right: 0;
            bottom: 0;

            &:hover {
              background: white;
            }
          }
        }

        &--item {
          display: block;
          height: 100%;
          width: 100%;
          border-radius: 50%;
          overflow: hidden;
        }
      }

      .user-profile-overview--availability {
        background: white;
        padding: 0;
        min-width: auto;
        position: absolute;
        right: 8px;
        bottom: 4px;
        border-radius: 28px;
        width: 28px;
        height: 28px;
        border: 3px solid transparent;
      }

      .user-profile-overview--contact-info {
        height: 120px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .location--wrapper {
          margin-top: size(20);
          display: flex;
          flex-direction: row;
          align-items: center;

          .country {
            margin-left: size(4);
          }
        }
      }
    }

    &--floating {
      flex: 0 0 40%;
      width: 40%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 170px;

      .profile--quick-links {
        align-items: center;
        display: flex;
        flex-direction: row;
        max-width: calc(100% - 40px);

        &.my-profile {
          position: relative;
        }

        &--link {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: size(66);
          width: 66px;
          height: 66px;
          background: white;
          margin-left: size(24);
          text-decoration: none;
          padding: size(10);
          box-sizing: border-box;
          box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12),
          0 4px 8px 0 rgba(0, 0, 0, 0.14);

          .icon--general {
            width: 20px;
            height: 20px;

            svg {
              width: 20px;
              height: 20px;
            }
          }

          path {
            fill: $black;
          }

          span {
            display: block;

            &.link-text {
              margin-top: size(6);
              text-decoration: none;
              font-size: size(14);
              color: $brand-10;
            }
          }

          &:hover {
            background: $grey-92;
          }
        }
      }
    }
  }

  .user-profile-avatar {
  }
}
