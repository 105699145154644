@import "../utilities";

.install {
  &-wrapper {
    max-width: size(1000);
    margin: size(50) auto;
  }

  &-intro {
    display: flex;
    padding: size(16);
    justify-content: center;
    align-items: flex-start;
    gap: size(32);
    align-self: stretch;
    border-radius: size(8);
    background: #f3f4f7;

    &--texts {
      display: flex;
      flex-direction: column;
      gap: size(32);
    }

    &--title {
      margin-top: 0;
      margin-bottom: size(10);
    }

    &--bottom {
      display: flex;
      flex-direction: row;
      gap: size(10);
    }
  }

  &__steps {
    display: flex;
    flex-direction: column;

    &--step {
      display: flex;
      flex-flow: row nowrap;
      gap: size(37);

      &.disabled {
        opacity: 0.3;
      }

      &-icon {
        background: linear-gradient(to left, white calc(50% - 1px), $grey-94 calc(50% - 1px) calc(50% + 1px), white calc(50% + 1px));

        .disabled & {
          opacity: 0.3;

          .icon-shadow {
            box-shadow: 0px 4px 10px -2px rgba(0, 0, 0, 0.8);
          }
        }
      }

      &:last-child {
        .install__steps--step-icon {
          background: none;
        }
      }

      &-content {
        padding-bottom: size(40);

        &.pb-0 {
          padding-bottom: 0;
        }

        &--why {
          display: flex;
          flex-font: row nowrap;
          gap: size(5);

          p:nth-child(2) {
            max-width: size(660);
          }
        }

        &--btnspin {
          display: flex;
          align-items: center;
          gap: size(24);
          flex-flow: row nowrap;

          .deliwi-spinner {
            width: size(40);
            height: size(40);

            &::after {
              width: size(40);
              height: size(40);
            }
          }
        }
      }
    }

    &--inputs {
      gap: size(16);
      max-width: size(415);

      .ms-TextField-wrapper {
        display: flex;
        flex-flow: row nowrap;
        width: 100%;
        justify-content: space-between;

        .ms-Label {
          @extend .text-style--Body1;
          color: $grey-44;
          font-weight: 400;
        }

        .ms-TextField-field {
          min-width: size(280);

          &::placeholder {
            color: $grey-70;
          }
        }
      }

      .ms-TextField-suffix {
        display: none;
      }

      .is-disabled {
        .ms-TextField-fieldGroup {
          background: $white;
          border: none;
          display: flex;
          align-items: center;

          .ms-TextField-field {
            background: $white;
            color: $brand-10;
            font-weight: 600;
            @include rhythm(18, 20);
          }

          .ms-TextField-suffix {
            display: flex;
            border-radius: 50%;
            background: $dark-green;
            width: size(24);
            height: size(24);
            line-height: size(24);
            max-width: none;
            padding: 0;
            justify-content: center;

            .checkmarkIcon {
              color: $white;
            }
          }
        }
      }
    }

    .ms-BasePicker {
      background: $white;

      &-text {
        border-radius: size(4);
        padding-left: size(12);
        padding-right: size(12);

        &::after {
          pointer-events: none;
          content: "";
          position: absolute;
          inset: -1px;
          border: 1px solid $border-divider;
          border-radius: size(4);
          border-bottom-color: $text-color--neutral-foreground-3;
        }
      }

      &-itemsWrapper {
        padding: size(9) size(0);
      }

      &-input {
        background: $white;
        padding-top: size(9);
        padding-bottom: size(9);
        @include rhythm(16, 22);
        height: auto;
      }
    }
  }

}
