@import "../utilities";

.user-report {
  &__top {
    border-bottom: 1px solid $border-sidebar;
  }

  &__row {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;

    > div {
      max-width: 33%;
      width: 100%;

      &:last-child {
        display: flex;
        justify-content: flex-end;
      }
    }

    &--title {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: size(26);
    }

    &--bar {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: size(16);

      &-bg {
        width: size(228);
        height: size(28);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.14), 0 0 2px rgba(0, 0, 0, 0.12);
        border-radius: size(24);
        overflow: hidden;
        position: relative;
        z-index: 2;

        &-none {
          opacity: 0;
        }
      }

      &-inner {
        background: $white;
        height: 100%;
        width: 100%;
        position: relative;
        z-index: 1;
      }
    }
  }

  &__cards {
    display: flex;
    flex-flow: row wrap;
    gap: size(32);

    &--card {
      display: flex;
      flex-grow: 1;
      flex-flow: column wrap;
      border: 1px solid #00B7C3;
      justify-content: center;
      align-items: center;
      gap: size(25);
      box-shadow: 0px 4.19318px 8.38636px rgba(0, 0, 0, 0.14), 0px 0px 2.09659px rgba(0, 0, 0, 0.12);
    }
  }
}
