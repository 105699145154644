@import "../utilities";

[data-tokens-mode="light"] {
  --tokens-surface-color-surface-icons: var(--primitives-greys-50);
}

[data-tokens-mode="dark"] {
  --tokens-surface-color-surface-icons: var(--primitives-brand-primary-100);
}

[data-tokens-mode="brand-dark"] {
  --tokens-surface-color-surface-icons: var(--primitives-brand-primary-100);
}

[data-tokens-mode="brand-light"] {
  --tokens-surface-color-surface-icons: var(--primitives-greys-50);
}

body,
html,
#root {
  width: 100%;
  display: flex;
  flex-direction: column;
}

body {
  //background: linear-gradient(157deg, #eceff3 -5.01%, #fff 18.3%);
  min-height: 100vh;
  font-family: $font-family-lato;
  @include rhythm(16, 22);
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}

#root {
  height: 100%;
  flex-grow: 1;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: $font-family-lato;
}

.app-wrapper {
  display: flex;
  flex-grow: 1;
  flex-flow: row nowrap;
  position: relative;
  background: linear-gradient(157deg, #ECEFF3 -5.01%, #FFF 18.3%);
}

.h-100 {
  height: 100%;
}

.h-100vh {
  height: 100vh;
}

.w-100 {
  width: 100%;
}

.content {
  //background-color: #fff;
  width: calc(100% - size(120));
  position: relative;
  margin-left: size(120);
}

.pilot-feedback {
  position: absolute;
  left: 50%;
  //right: auto;
  z-index: 4;
  top: size(63);
  width: 100%;
  max-width: size(1200);
  transform: translateX(-50%);
  text-align: right;

  a {
    display: inline-block;
  }

  button {
  }
}

.long-text--container {
  background: $green-light;
  padding: size(16);
  border-radius: size(16);
}

.limit-long-text {
  max-width: size(750);
}

.info-text {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M12.0001 1.99902C17.5238 1.99902 22.0016 6.47687 22.0016 12.0006C22.0016 17.5243 17.5238 22.0021 12.0001 22.0021C6.47638 22.0021 1.99854 17.5243 1.99854 12.0006C1.99854 6.47687 6.47638 1.99902 12.0001 1.99902ZM11.9963 10.2496C11.4834 10.2499 11.061 10.6363 11.0036 11.1336L10.9969 11.2503L11.0005 16.7519L11.0073 16.8685C11.0654 17.3658 11.4884 17.7515 12.0012 17.7512C12.514 17.7509 12.9364 17.3645 12.9939 16.8672L13.0005 16.7505L12.9969 11.249L12.9901 11.1323C12.932 10.635 12.5091 10.2493 11.9963 10.2496ZM12.0005 6.50006C11.3093 6.50006 10.749 7.06038 10.749 7.75158C10.749 8.44277 11.3093 9.0031 12.0005 9.0031C12.6917 9.0031 13.2521 8.44277 13.2521 7.75158C13.2521 7.06038 12.6917 6.50006 12.0005 6.50006Z' fill='%2308757A'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: top left;

  @include rhythm(18, 24);

  padding-left: size(34);
  font-weight: 600;
  color: $brand-80;
}

[class*="ms-"]:not(.ms-Checkbox-checkmark) {
  font-family: $font-family-lato;
}

.delete-icon-button {
  color: #d4d8df;
}

.ms-ChoiceField-field {
  border-color: $brand-80;

  &::before {
    border-color: $brand-80;
  }

  &.is-checked {
    &::after {
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-color: $brand-80;
      background: $brand-80;
    }

    &:hover {
      &::after {
        background: $brand-80;
      }
    }
  }

  &:hover {
    &::after {
      background: $grey-92;
    }
  }
}

.d- {
  &block {
    display: block;
  }
}

button {
  cursor: pointer;
  white-space: nowrap;

  .deliwibutton-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row nowrap;
    column-gap: size(8);
    height: 20px;
    line-height: 0;

    > label {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: row nowrap;
      column-gap: size(8);
      height: 20px;
      line-height: 0;
    }
  }

  &:has(.icon--general) .deliwibutton-wrapper {
    line-height: 20px;
  }

  .icon--general {
    margin: 0;
    line-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
    }
  }

  svg {
    margin: 0;
  }
}

.button {
  &-style {
    &--primary {
      @include button-style("primary");

      svg {
        path {
          fill: white;
        }
      }
    }

    &--secondary {
      @include button-style("secondary");

      svg {
        path {
          fill: white;
        }
      }
    }

    &--outline {
      @include button-style("outline");

      svg {
        path {
          fill: black;
        }
      }
    }

    &--candy-border:not([disabled]) {
      @include button-style("outline");
      border: 1px solid transparent;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: size(30);
        border: 1px solid transparent; /*2*/
        background: linear-gradient(45deg, #0430ec, #0ebbb3) border-box; /*3*/
        -webkit-mask: /*4*/
                linear-gradient(#fff 0 0) padding-box,
                linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor; /*5'*/
        mask-composite: exclude; /*5*/
      }
    }

    &--delete {
      @include button-style("secondary");

      svg {
        path {
          fill: white;
        }
      }

      &:hover {
        background: $dark-red;
      }
    }

    &--candy {
      @include button-style("candy");

      svg {
        path {
          fill: white;
        }
      }
    }

    &--reset {
      padding: 0;
      background: none;
      border: none;

      &:hover {
        border: 0;
        background: none;
      }
    }

    &--newitem {
      background: none;
      border: none;
      font-size: size(18);
      font-weight: 400;
      color: $grey-44;
      padding-left: 0;

      .icon--general {
        background: $brand-80;
        border-radius: size(99);
        padding: size(6);
        width: size(20);
        height: size(20);
        transition: all 0.15s ease-in;

        svg {
          max-height: size(15);
        }
      }

      &:hover {
        background: none;
        color: $brand-10;

        .icon--general {
          background: $button--primary--hover--bg;
        }
      }
    }

    &--showmore {
      @include button-style("showmore");
    }
  }

  &-size {
    &--large {
      @include button("large");

      .icon--general {
        width: size(20);
        margin-bottom: -2px;

        svg {
          max-height: size(20);
        }
      }

      .deliwibutton-wrapper {
        margin-top: -2px;
        column-gap: size(8);
      }
    }

    &--medium {
      @include button("medium");

      .icon--general {
        width: size(16);
        margin-bottom: -2px;

        svg {
          max-height: size(16);
        }
      }

      .deliwibutton-wrapper {
        margin-top: -1px;
        column-gap: size(6);
      }
    }

    &--small {
      @include button("small");

      .icon--general {
        width: size(14);

        svg {
          max-height: size(14);
        }
      }

      .deliwibutton-wrapper {
        column-gap: size(5);
      }
    }

    &--largeicon {
      @include button("largeicon");

      .icon--general {
        width: size(20);

        svg {
          max-height: size(20);
        }
      }

      .deliwibutton-wrapper {
        height: auto;
      }
    }

    &--mediumicon {
      @include button("mediumicon");

      .icon--general {
        width: size(16);

        svg {
          max-height: size(16);
        }
      }

      .deliwibutton-wrapper {
        height: auto;
      }
    }

    &--smallicon {
      @include button("smallicon");

      .icon--general {
        width: size(16);

        svg {
          max-height: size(16);
        }
      }

      .deliwibutton-wrapper {
        height: auto;
      }
    }

    &--showmore {
      @include button("showmore");

      .icon--general {
        width: size(10);

        svg {
          max-height: size(10);
        }
      }
    }
  }
}

.icon {
  &-size {
    &--10 {
      &.icon--general {
        width: size(10);

        svg {
          max-height: size(10);
        }
      }
    }

    &--12 {
      &.icon--general {
        width: size(12);

        svg {
          max-height: size(12);
        }
      }
    }

    &--14 {
      &.icon--general {
        width: size(14);

        svg {
          max-height: size(14);
        }
      }
    }

    &--16 {
      &.icon--general {
        width: size(16);

        svg {
          max-height: size(16);
        }
      }
    }

    &--18 {
      &.icon--general {
        width: size(18);

        svg {
          max-height: size(18);
        }
      }
    }

    &--20 {
      &.icon--general {
        width: size(20);

        svg {
          max-height: size(20);
        }
      }
    }
  }
}

button {
  &.is-disabled,
  &[disabled] {
    background: $grey-94;
    cursor: not-allowed;
    color: $grey-70;
    border: 1px solid $grey-70;

    svg {
      path {
        fill: $grey-70;
      }
    }

    &:hover {
      background: $grey-94;
      cursor: not-allowed;
      color: $grey-70;
      border: 1px solid $grey-70;
    }
  }
}

.tag {
  &--candy {
    position: relative;
    padding: size(8) size(16);
    border-radius: size(30);
    color: $text-color--brand-10;
    display: inline-block;
    background: $white;

    &:before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: size(30);
      border: 2px solid transparent;
      background: linear-gradient(45deg, #0430ec, #0ebbb3) border-box;
      -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }

    &.active {
      background: linear-gradient(90deg, #0000e6 -11.66%, #27a9a3 97.22%);
      color: white;

      &:before {
        border: none;
        background: none;
      }
    }
  }

  &--checkmark {
    display: flex;
    align-items: center;
    gap: size(8);
    flex-flow: row nowrap;

    &:before {
      margin-top: size(1);
      content: "";
      display: block;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: size(17);
      height: size(13);
      background-image: url("data:image/svg+xml,%3Csvg width='17' height='13' viewBox='0 0 17 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.53212 10.9072L2.06056 7.00173C1.78538 6.69214 1.31132 6.66425 1.00174 6.93944C0.692148 7.21463 0.664263 7.68869 0.939451 7.99827L4.93945 12.4983C5.22614 12.8208 5.7252 12.8355 6.03034 12.5303L16.5303 2.03033C16.8232 1.73744 16.8232 1.26256 16.5303 0.96967C16.2374 0.676777 15.7626 0.676777 15.4697 0.96967L5.53212 10.9072Z' fill='%23707070'/%3E%3C/svg%3E%0A");
    }
  }

  &--inactive {
    color: $grey-44;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: size(8) size(16);
    background: $white;
    border: 1px solid $border-skill;
    border-radius: size(99);


  }
}

.ms {
  &-Button {
    &-flexContainer {
      // reset fluent ui default buttons label styles
      height: auto;
    }

    &-label {
      // reset fluent ui default buttons label styles
      font-weight: inherit;
      margin: 0;
      line-height: inherit;
      display: block;
    }
  }

  &-Pivot {
    &-link {
      &.is-selected::before {
        height: 3px;
        border-radius: 10px;
      }
    }
  }
}

.m {
  &b {
    &-0 {
      margin-bottom: 0;
    }

    &-4 {
      margin-bottom: size(4);
    }

    &-8 {
      margin-bottom: size(8);
    }

    &-12 {
      margin-bottom: size(12);
    }

    &-16 {
      margin-bottom: size(16);
    }

    &-24 {
      margin-bottom: size(24);
    }

    &-32 {
      margin-bottom: size(32);
    }

    &-40 {
      margin-bottom: size(40);
    }

    &-48 {
      margin-bottom: size(48);
    }

    &-60 {
      margin-bottom: size(60);
    }
  }

  &t {
    &-0 {
      margin-top: 0;
    }

    &-4 {
      margin-top: size(4);
    }

    &-8 {
      margin-top: size(8);
    }

    &-16 {
      margin-top: size(16);
    }

    &-24 {
      margin-top: size(24);
    }

    &-32 {
      margin-top: size(32);
    }

    &-40 {
      margin-top: size(40);
    }

    &-48 {
      margin-top: size(48);
    }
  }

  &r {
    &-auto {
      margin-right: auto;
    }
  }

  &x {
    &-auto {
      margin-right: auto;
      margin-left: auto;
    }
  }

  &l {
    &-auto {
      margin-left: auto;
    }

    &-24 {
      margin-left: size(24);
    }
  }
}

.p {
  &b {
    &-0 {
      padding-bottom: 0;
    }

    &-4 {
      padding-bottom: size(4);
    }

    &-8 {
      padding-bottom: size(8);
    }

    &-12 {
      padding-bottom: size(12);
    }

    &-16 {
      padding-bottom: size(16);
    }

    &-24 {
      padding-bottom: size(24);
    }

    &-32 {
      padding-bottom: size(32);
    }

    &-40 {
      padding-bottom: size(40);
    }

    &-48 {
      padding-bottom: size(48);
    }

    &-60 {
      padding-bottom: size(60);
    }
  }

  &t {
    &-0 {
      padding-top: 0;
    }

    &-4 {
      padding-top: size(4);
    }

    &-8 {
      padding-top: size(8);
    }

    &-16 {
      padding-top: size(16);
    }

    &-24 {
      padding-top: size(24);
    }

    &-32 {
      padding-top: size(32);
    }

    &-40 {
      padding-top: size(40);
    }

    &-48 {
      padding-top: size(48);
    }
  }

  &r {
    &-auto {
      margin-right: auto;
    }
  }

  &x {
    &-auto {
      margin-right: auto;
      margin-left: auto;
    }
  }

  &l {
    &-auto {
      margin-left: auto;
    }

    &-24 {
      margin-left: size(24);
    }
  }
}

.bg {
  &-white {
    background: $white;

    &:hover {
      background: $white;
    }
  }
}

.box {
  &-gray {
    padding: size(24);
    background-color: $grey-94;
    border-radius: size(18);
  }

  &-green {
    padding: size(24);
    background-color: $bg-green;
    border-radius: size(18);
  }
}

.radius {
  &-18 {
    border-radius: size(18);
  }
}

.line {
  &-clamp {
    &-2 {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

.icon {
  &-size {
    &-62 {
      width: size(62);
      height: size(62);
      max-width: none;
    }
  }

  &-shadow {
    box-shadow: 0px 4px 10px -2px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    margin-left: size(4);
  }
}

.deliwi-spinner {
  width: size(48);
  height: size(48);
  border-radius: 50%;
  display: block;
  border-top: size(4) solid $brand-80;
  border-right: size(4) solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  &::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: size(48);
    height: size(48);
    border-radius: 50%;
    border-left: size(4) solid $brand-140;
    border-bottom: size(4) solid transparent;
    animation: rotation 0.5s linear infinite reverse;
  }

  &__small {
    margin-right: size(5);
    margin-bottom: size(3);

    .deliwi-spinner {
      width: size(24);
      height: size(24);

      &::after {
        width: size(24);
        height: size(24);
      }
    }
  }

  &--wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    padding-top: size(150);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.load-wrapper {
  padding: size(50);
}

.read-more-btn {
  display: block;
  padding: 0;
  margin: 0;
  margin-left: auto;
  font-size: size(15);
  color: $brand-primary;
  background: none;
  border: none;
  cursor: pointer;
  //box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 4px 8px 0 rgba(0, 0, 0, 0.14);
  //border-radius: 24px;
}

.generalmodal {
  &__title {
    border-bottom: 1px solid $border-divider;
  }
}

.ms-Modal .ms-Dialog-main .ms-Modal-scrollableContent {
  overflow-y: visible;
}

.react-datepicker-wrapper input,
.ms-TextField-fieldGroup {
  border: 1px solid $border-divider;
  border-radius: size(4);
  border-bottom: 1px solid $grey-44;

  &:after {
    border: none;
    border-bottom: 2px solid $grey-34;
  }

  .ms-TextField-field {
    padding: 0 size(10);
  }

  textarea.ms-TextField-field {
    padding: size(10);
  }
}

input,
textarea,
.ms-TextField-fieldGroup,
.ms-TextField-field,
.ms-ComboBox,
.ms-ComboBox-Input {
  @extend .text-style--Body2;
  color: $text-color--neutral-foreground;
  font-family: $font-family-lato;

  &::placeholder {
    color: $grey-70;
  }

  label {
    font-family: $font-family-lato;
  }
}

.react-datepicker-wrapper input {
  @extend .text-style--Body2;
  color: $text-color--neutral-foreground;
  width: calc(100% - 20px);
  min-height: 32px;
  padding: 0 10px;

  &::placeholder {
    color: $grey-70;
  }
}

.ms-ComboBox {
  border: 1px solid $border-divider;
  border-radius: size(4);
  border-bottom: 1px solid $grey-44;

  &:after {
    border: 1px solid $border-divider;
  }

  &:focus-within::after {
    border: none;
    border-bottom: 2px solid $grey-34;
  }

  .ms-TextField-field {
    padding: 0 size(10);
  }
}

.ms-PeoplePicker-personaContent {
  padding: 4px 0 4px 12px;

  .ms-PeoplePicker-Persona {
    width: 250px;
  }
}
