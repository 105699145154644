@import "../utilities";

.score-wrap {
  display: flex;
  justify-content: center;
}

.score {
  width: 200px;
  height: 177px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  .score-bar {
    position: absolute;
    width: 100%;
    height: 200%;
    border-radius: 50%;
    top: 0;

    .placeholder {
      svg {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.14))
      }
    }

    .score-circle {
      position: absolute;
      top: 0;
    }
  }
}


.score-value {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .score-icon {
    width: 62px;
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 99px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 4px 8px 0 rgba(0, 0, 0, 0.14);

    .icon--general {
      width: 34px;
      height: 34px;
    }
  }
}

.score-level-name {
  margin: 4px 0 5px;
}

.score-title {
  @include rhythm(14, 20);
  color: $text-color--neutral-foreground;
}

.score-number {
  color: $text-color--neutral-foreground;
}