@import "../utilities";

.sidebar {
  display: flex;
  width: size(120);
  height: 100%;
  flex-shrink: 0;
  position: fixed;
  left: 0;
  z-index: 1;

  &-nav {
    flex-grow: 1;
    height: 100vh;
    padding: size(48) size(16) size(32);
    position: fixed;
    z-index: 10;
    width: size(120);
    background: $white;
    border-right: 1px solid $border-sidebar;

    &__links {
      display: flex;
      flex-direction: column;
      gap: size(32);
      text-align: center;
      flex-grow: 1;

      &--link {
        display: flex;
        align-items: center;
        text-align: center;
        flex-direction: column;
        @extend .text-style--Body2;

        span:not(.sidebar-nav__links--link-icon) {
          color: $nav-link;
          padding-top: size(2);
        }

        svg,
        path {
          fill: $icon-normal;
        }

        &.link-logout {
          margin-top: auto;

          .sidebar-nav__links--link-icon {
            width: 20px;
            height: 20px;
          }
        }

        &-icon.icon--general {
          font-size: size(24);
          width: 24px;
          height: 24px;
          padding: 4px 18px;
          margin: 0 0 size(4) 0;

          &.ai-search {
            width: 24px;
            height: 24px;
          }
        }

        &:hover {
          text-decoration: none;
          color: $nav-link-hover;

          span {
            color: $nav-link-hover;
            text-decoration: none;
          }

          .sidebar-nav__links--link-icon {
            border-radius: size(99);
            background: $brand-160;

            svg path {
              fill: $icon-active;
            }

            span {
              color: $nav-link-hover;
              text-decoration: none;
            }
          }
        }

        &.active {
          .sidebar-nav__links--link-icon {
            border-radius: size(99);
            background: $icon-active-bg;

            svg path {
              fill: $icon-active;
            }
          }

          span {
            color: $brand-10;
          }
        }
      }
    }
  }

  &-logo {
    display: inline-block;
    margin: 0 auto size(50) auto;
    width: 25px;
  }
}
