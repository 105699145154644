@import "../utilities";

.help-center {
  &-page {
    display: flex;
    flex-shrink: 1;
    margin: 0 auto;
    max-width: size(1024);
    padding: size(48) size(24);

    &.qa-item-full-display-opened {
      width: 90%;
      max-width: size(1440);
    }

    &__container {
      width: 100%;
      margin: 0 auto;
      text-align: left;

      h1.text-style--Large-title {
        margin-bottom: size(32);
      }
    }

    &.qa-item-full-display-opened {
      width: 90%;
      max-width: size(1440);

      .help-center-page__container {
        padding-right: 24px;
      }
    }

    &__tabs {
      width: 100%;
      text-align: left;

      >.ms-Pivot {
        margin-bottom: size(32);
        white-space: normal;
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        column-gap: size(24);
        row-gap: size(10);
      }

      .ms-Pivot-link {
        padding: size(12);
        margin-right: 0;
        font-family: $font-family-lato;

        @extend .text-style--Body1;

        .ms-Pivot-text {

          color: $grey-32;
          @extend .text-style--Body1;
        }

        &::before,
        &::after {
          //left: 0;
          //right: 0;
        }

        &.is-selected {
          &::before {
            background-color: $brand-80;
          }
        }
      }

      &--tabcontent {
        text-align: left;
      }
    }

    .ms-Dialog-main {
      border-radius: size(18);
    }

    .ms-DetailsRow {
      @include rhythm(16, 22);
      color: $brand-10;

      &.is-selected {
        background: $brand-160;
      }
    }

    .ms-DetailsRow-cell {
      min-height: size(60);
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .ms-DetailsHeader-cellName {
      font-size: size(14);
      font-weight: 400;
      color: $label-display;
    }

    .ms-DetailsRow {
      &:hover {
        background: $grey-bg;

        .toolscolumn {
          padding-left: 0 !important;
          padding-right: 0 !important;
          position: relative;
          overflow: visible;
        }

        .admin-usertable__hidden {
          width: auto;
          position: absolute;
          top: 0;
          right: 0;
          overflow: visible;
          z-index: 100;
          padding-right: size(24);
          line-height: 1;
        }
      }
    }

    .text-align--center .ms-DetailsHeader-cellTitle {
      justify-content: center;
    }

    .toolscolumn {
      padding-left: 0 !important;
      padding-right: 0 !important;
      position: relative;
      overflow: hidden;
    }

    .search-field {
      max-width: 506px;
      margin-bottom: size(32);

      &__button {
        padding-left: 2px;
        padding-right: 2px;

        .deliwibutton-wrapper {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  &-dialog {
    .ms-Dialog-main {
      border-radius: size(18);
      min-width: size(850);
    }

    &--delete {
      .ms-Dialog-main {
        border-radius: size(18);
        min-width: 0;
        width: 100%;
        max-width: size(435);
      }
    }
  }

  &-bullet-list {
    ul {
      list-style-type: circle;
    }

  }

  &-box {
    border-radius: size(24);
    border: 1px solid $border-divider;
    background-color: $white;
    padding: size(32) size(32) 0 size(32);
    position: relative;
    margin-bottom: size(32);

    &__iconset {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-flow: row nowrap;
      gap: size(16);

      &--wrapper {
        width: 62px;
        height: 62px;
        background: white;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0;
        flex: 0 0 62px;

        img {
          width: 28px;
          height: 28px;
        }
      }

      &-alignstart {
        align-items: flex-start;
      }
    }

    &__graywrap {
      border-radius: size(16);
      padding: size(16);
      background: $grey-94;
    }

    .qa-category--items {
      border-top: 1px solid $border-sidebar;
      padding-top: size(32);
    }

    .qa-item--container {
      border-bottom: 0.5px solid $border-divider;

      &:hover:not(.item-opened) {
        background: linear-gradient(90deg, #FFF 0%, #F9F9F9 100%);
      }

      &:last-child {
        border-bottom: none;
        padding-bottom: size(32);
      }

      .qa-item--header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        padding: size(16) size(24) size(16) size(80);

        &:hover {
          color: $brand-80;
        }

        .icon--general {
          width: 16px;
          height: 16px;

          svg {
            transition: .1s;
            width: 16px;
            height: 16px;

            path {
              fill: black;
            }
          }
        }
      }

      &.item-opened {
        color: $brand-80;
        background: $brand-160;
        padding-bottom: size(16);

        .qa-item--header {

          .icon--general {
            svg {
              transform: rotate(180deg);

              path {
                fill: $brand-80;
              }
            }
          }
        }

        button.read-more {
          color: $button--primary--hover--text;
          border: 1px solid $button--primary--hover--bg;
          margin-top: size(24);
        }

        .qa-item--answer-content {
          padding: 0 size(24) 0 size(80);
        }


        .qa-item--short-description {
          color: black;
        }
      }

      &.item-opened-in-full-display {
        button.read-more {
          background: $button--primary--hover--bg;
          color: $button--primary--hover--text;
          border: 1px solid $button--primary--hover--bg;
          margin-top: size(24);
        }
      }
    }
  }

  &-teams {
    &__intro {
      display: flex;
      padding: size(32);
      justify-content: flex-start;
      align-items: flex-start;
      gap: size(16);
      align-self: stretch;
      border-radius: size(8);
      background: #f3f4f7;
      margin-bottom: size(40);

      &--texts {
        display: flex;
        flex-direction: column;
        gap: size(16);
        max-width: size(710);
      }

      &--bottom {
        display: flex;
        flex-direction: row;
        gap: size(10);
      }
    }

    &__toggle {
      background-color: $brand-160;
      border-radius: size(8);
      padding: size(24) size(16);
      width: 100%;
      max-width: size(480);

      .ms-Toggle {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        margin-bottom: 0;

        &-innerContainer {
          button {
            order: 2;
          }
        }

        &-background {
          background-color: $brand-primary;

          &:hover {
            background-color: $brand-active;
          }
        }

        .ms-Label {
          @extend .text-style--Body1;
          font-weight: 400;
          line-height: 1;
        }
      }
    }
  }

  &-qa {
    &--category {
      width: 100%;

      &--description-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
      }

      .qa-category {
        &--items-trigger {
          cursor: pointer;
          color: $brand-80;
          display: flex;
          align-items: center;
          flex: 0 0 auto;
          margin-left: 32px;

          .icon--general {
            margin-left: 8px;
            width: 20px;
            height: 20px;

            svg {
              path {
                fill: $brand-80;
              }
            }
          }


        }
      }
    }

  }

  &-search {
    &__userlist {
      display: flex;
      flex-flow: row nowrap;
      gap: size(24);
      align-items: center;

      &-input {
        padding: size(11) size(50) size(11) size(50);
        border-radius: size(24);
        border: 1px solid $brand-stroke;
        min-width: size(320);

        @include rhythm(16, 16);
        color: $text-color--neutral-foreground-3;

        background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.25 0C11.2541 0 14.5 3.24594 14.5 7.25C14.5 8.98187 13.8927 10.5719 12.8795 11.8188L17.7803 16.7197C18.0732 17.0126 18.0732 17.4874 17.7803 17.7803C17.5141 18.0466 17.0974 18.0708 16.8038 17.8529L16.7197 17.7803L11.8188 12.8795C10.5719 13.8927 8.98187 14.5 7.25 14.5C3.24594 14.5 0 11.2541 0 7.25C0 3.24594 3.24594 0 7.25 0ZM7.25 1.5C4.07436 1.5 1.5 4.07436 1.5 7.25C1.5 10.4256 4.07436 13 7.25 13C10.4256 13 13 10.4256 13 7.25C13 4.07436 10.4256 1.5 7.25 1.5Z' fill='%23C2C2C2'/%3E%3C/svg%3E%0A");
        background-position: left size(16) center;
        background-repeat: no-repeat;
        background-size: size(18) size(18);
      }
    }

    &__paging {
      display: flex;
      align-items: center;
      gap: size(8);

      span {
        display: block;
      }

      &--dropdown .ms-Dropdown-title {
        border-radius: size(4);
        border-color: $grey-70;
      }
    }
  }

  &--contact-support {
    border-radius: size(24);
    background: linear-gradient(99deg, #00C6D3 3.65%, #0276E3 96.86%);

    .chat-area--container {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    .service-form-chat-container {
      width: 60%;
      flex: 0 0 60%;

      @media all and (min-width: 920px) {
        width: 70%;
        flex: 0 0 70%;
        max-width: 600px;
      }
    }

    .chat-friend-image {}
  }
}

.icon {
  &-size {
    &-62 {
      width: size(62);
      height: size(62);
      max-width: none;
    }
  }

  &-shadow {
    box-shadow: 0px 4px 10px -2px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    margin-left: size(4);
  }
}