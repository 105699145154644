@import "../components/SearchField";

.search-greeting,
.search-description {
  color: #202637;
  font-family: "Lato-Bold", Helvetica, "sans-serif";
  font-size: size(32);
  font-weight: 400;
  letter-spacing: 0;
  line-height: size(37.4);
  max-width: 807px;
}

.search-greeting {
  margin-bottom: size(20);
}

.search-description {
  margin-bottom: size(30);

  .bold {
    font-weight: 600;
  }

  .italic {
    font-family: "Lato-Italic", Helvetica, "sans-serif";
    font-style: italic;
  }
}

.search-field {
  max-width: 807px;
}

.search-instructions {
  &--boxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  &--box {
    flex: 0 0 50%;
    width: 50%;
    padding: size(8);
    box-sizing: border-box;
    white-space: pre-line;

    &--container {
      padding: size(16);
      box-sizing: border-box;
      height: 100%;
      background-color: $grey-94;
      border-radius: size(8);
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12),
      0 2px 4px 0 rgba(0, 0, 0, 0.14);
    }
  }

  .search-instruction {
    &--label--wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: size(16);
    }

    &--label--text {
      margin: size(-3) 0 0 size(8);
    }

    &--content {
      margin-bottom: size(16);
    }
  }
}

.profile-search--loading {
  //width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: size(8);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12),
  0 2px 4px 0 rgba(0, 0, 0, 0.14);
  padding: size(40) size(16);
  text-align: center;

  &--animation {
    height: 100px;
  }

  progress[value] {

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    width: 440px;
    margin: size(24) size(10);
    border-radius: size(24);
    background: white;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.14), 0px 0px 2px 0px rgba(0, 0, 0, 0.12);
  }

  progress[value]::-webkit-progress-bar {
    border-radius: size(24);
    background: white;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.14), 0px 0px 2px 0px rgba(0, 0, 0, 0.12);
  }

  progress[value]::-webkit-progress-value {
    border-radius: size(24);
    background: linear-gradient(90deg, #0D4FF8 -8.33%, #00B7C3 85.71%);;
  }

  progress[value]::-moz-progress-bar {
    border-radius: size(24);
    background: linear-gradient(90deg, #0D4FF8 -8.33%, #00B7C3 85.71%);;
  }

  .css-typing {
    display: flex;
    flex-direction: column;

    p {
      border-right: 0.15em solid $brand-140;
      white-space: nowrap;
      overflow: hidden;
    }

    p:nth-child(1) {
      width: 400px;
      -webkit-animation: type 2s steps(100, end);
      animation: type2 2s steps(100, end);
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
    }

    p:nth-child(2) {
      width: 400px;
      opacity: 0;
      -webkit-animation: type2 2s steps(100, end);
      animation: type2 2s steps(100, end);
      -webkit-animation-delay: 2s;
      animation-delay: 2s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
    }

    @keyframes type {
      0% {
        width: 0;
      }
      99.9% {
        border-right: 0.15em solid $brand-140;
      }
      100% {
        border: none;
      }
    }

    @-webkit-keyframes type {
      0% {
        width: 0;
      }
      99.9% {
        border-right: 0.15em solid $brand-140;
      }
      100% {
        border: none;
      }
    }

    @keyframes type2 {
      0% {
        width: 0;
      }
      1% {
        opacity: 1;
      }
      99.9% {
        border-right: 0.15em solid $brand-140;
      }
      100% {
        opacity: 1;
        border: none;
      }
    }

    @-webkit-keyframes type2 {
      0% {
        width: 0;
      }
      1% {
        opacity: 1;
      }
      99.9% {
        border-right: 0.15em solid $brand-140;
      }
      100% {
        opacity: 1;
        border: none;
      }
    }

    @keyframes blink {
      50% {
        border-color: transparent;
      }
    }
    @-webkit-keyframes blink {
      50% {
        border-color: transparent;
      }
    }
  }
}

.profile-search--no-results {
  width: 100%;
  border-radius: size(8);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12),
  0 2px 4px 0 rgba(0, 0, 0, 0.14);
  padding: size(40) size(16);
  text-align: center;

  .no-results {
    &--wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &--image {
      height: 200px;
    }

    &--text-content {
      display: flex;
      flex-direction: column;
      padding: 0 4rem;

      h3 {
        margin-bottom: size(12);
      }

      p {
        margin-bottom: size(12);
        margin-top: 0;
      }
    }
  }
}

.profile-search-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  &--container {
    max-width: size(1280);
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    padding: size(48) size(24) size(100);
    box-sizing: border-box;
    position: relative;

    &.preview-state {
      max-width: size(1400);
    }
  }

  &--overview {
    text-align: center;
  }

  &--tour {
    //width: 100%;
    width: calc(100% - size(120));
    //margin-left: size(120);
    text-align: right;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    position: fixed;
    bottom: size(32);
    right: 0;
    z-index: 0;

    .quicktour {
      max-width: size(1280);
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      bottom: 0;
      //padding-right: size(24);

      button {
        margin-right: size(24);
      }
    }
  }
}

.profile-search {
  &--search-area {
    &--title {
      max-width: size(807);
    }

    &--search {
      display: flex;
      flex-flow: row nowrap;
      gap: size(24);
      align-items: center;
      justify-content: flex-start;
    }

    &--container {
      flex-basis: 100%;
      width: 100%;
      position: relative;
      z-index: 2;

      &.preview-state {
        flex-basis: 40%;
        width: 40%;
        padding-right: size(24);
        flex-grow: 1;

        @media all and (min-width: 1024px) {
          flex-basis: auto;
          width: auto;
        }
      }
    }
  }

  &--content {
    margin-top: size(64);
  }

  &--results {
    display: flex;
    flex-wrap: wrap;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    width: calc(100% + 1rem);

    &--result--wrapper {
      flex-basis: 100%;
      width: 100%;
      box-sizing: border-box;
      padding: 0.5rem;

      &:hover {
        border-color: $brand-stroke-hover;
      }

      max-width: size(280);

      @media all and (min-width: 585px) {
        flex-basis: 50%;
        width: 50%;
      }
      @media all and (min-width: 768px) {
        flex-basis: 33%;
        width: 33%;
      }
      @media all and (min-width: 1024px) {
        flex-basis: 25%;
        width: 25%;
      }
      @media all and (min-width: 1240px) {
        flex-basis: 20%;
        width: 20%;
      }

      .preview-state & {
        flex-basis: 100%;
        width: 100%;
        @media all and (min-width: 1024px) {
          flex-basis: 50%;
          width: 50%;
        }
        @media all and (min-width: 1240px) {
          flex-basis: 33%;
          width: 33%;
        }
      }
    }

    &--result {
      border-radius: size(8);
      border: 1px solid $brand-stroke;
      background-color: $white;

      &:hover {
        border-color: $brand-stroke-hover;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12),
        0 8px 16px 0 rgba(0, 0, 0, 0.14);
      }
    }
  }
}
