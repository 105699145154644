@import "../utilities";

.userlist {
  &__pagination {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: size(5);
    justify-content: flex-end;
    margin-top: size(8);
    flex-flow: row wrap;

    button {
      width: size(27);
      color: $brand-10;
      border-radius: size(24);
      background: none;
      border: none;
      margin: 0;
      cursor: pointer;
      padding: 0;
      @include rhythm(12, 20);
      font-weight: 600;

      &[disabled] {
        background: $grey-92;
      }
    }
  }
}
