:root {
  --grey-palette-white: rgba(255, 255, 255, 1);
  --primitives-borders-lg: 30px;
  --primitives-borders-md: 16px;
  --primitives-borders-s: 8px;
  --primitives-brand-primary-100: rgba(190, 241, 232, 1);
  --primitives-brand-primary-300: rgba(34, 182, 160, 1);
  --primitives-brand-primary-400: rgba(0, 152, 160, 1);
  --primitives-brand-secondary: rgba(0, 76, 255, 1);
  --primitives-brand-tertiary: rgba(137, 68, 197, 1);
  --primitives-greys-0: rgba(255, 255, 255, 1);
  --primitives-greys-100: rgba(228, 229, 232, 1);
  --primitives-greys-200: rgba(193, 199, 207, 1);
  --primitives-greys-350: rgba(94, 105, 113, 1);
  --primitives-greys-400: rgba(89, 89, 89, 1);
  --primitives-greys-50: rgba(236, 238, 243, 1);
  --primitives-greys-800: rgba(32, 38, 55, 1);
  --tokens-border-radius-border-radius-medium: var(--primitives-borders-md);
  --tokens-border-radius-border-radius-rounded: var(--primitives-borders-lg);
  --tokens-border-radius-border-radius-small: var(--primitives-borders-s);
  --tokens-surface-color-surface-icons: var(--primitives-greys-50);
}
