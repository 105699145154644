@import "../utilities";

.search-field {
  display: flex;
  align-items: flex-end;
  flex-grow: 1;
  border: 1px solid $brand-140;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12),
  0 4px 8px 0 rgba(0, 0, 0, 0.14);
  border-radius: size(28);
  padding: size(2) size(16);

  &.is-active {
    border: 1px solid $light-teal;
  }

  &:hover {
    border: 1px solid $brand-pressed;
  }

  &__input {
    width: 100%;
    margin: 0;

    textarea {
      resize: none;
    }

    .ms-TextField-fieldGroup {
      border-width: 0;
      height: auto;


      .ms-TextField-field {
        border: none;
        border-width: 0;
        padding: size(13) 0;
        color: $grey-38;
      }
    }

    &.is-active {

      .ms-TextField-fieldGroup {
        border-width: 0;

        &:after {
          display: none;
          content: "";
          border-width: 0;
        }

        .ms-TextField-field {
          border-width: 0;
          color: $grey-32;
        }
      }
    }

    > div {
      color: #595756;
      font-family: $font-family-lato;
      font-size: size(16);
      font-weight: 400;
      //min-height: size(53);
      //border-radius: size(28);
      padding: 0;

      //> fieldset {
      //  border: 1px solid $brand-stroke;
      //  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12),
      //  0 4px 8px 0 rgba(0, 0, 0, 0.14);
      //}

      //textarea {
      //  padding: size(14) size(22);
      //}
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    margin-bottom: size(3);
    justify-content: flex-end;
  }

  &__button {
    background: none;
    padding: size(11) size(12);
    border: none;
    flex-shrink: 0;

    &[disabled] {
      background: none;
      padding: size(11) size(12);
      border: none;
      flex-shrink: 0;

      &:hover,
      &:focus {
        background: none;
        border: none;
      }
    }

    &:focus {
      outline: none;
    }

    &--green {
      svg path {
        fill: $brand-pressed;
      }
    }

    &--mic {
      display: flex;
      align-items: center;
      flex-flow: row nowrap;
      gap: size(12);
      padding-left: 0;
      padding-right: size(6);
      padding-top: 0;
      padding-bottom: 0;

      &:before {
        height: size(33);
        content: "";
        border-left: 1px solid $border-divider;
      }

      &:hover,
      &:focus {
        svg path {
          fill: $brand-pressed;
        }
      }
    }
  }

}
