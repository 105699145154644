@font-face {
  font-family: "Lato";
  src: url("../../fonts/Lato-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Lato";
  src: url("../../fonts/Lato-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lato";
  src: url("../../fonts/Lato-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Lato-Bold";
  src: url("../../fonts/Lato-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Lato";
  src: url("../../fonts/Lato-BoldItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: "Open Sans";
  src: url("../../fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Open Sans";
  src: url("../../fonts/OpenSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "WorkSans";
  src: url("../../fonts/WorkSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
}
