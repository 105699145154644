@import "../utilities";

.profile-preview-overview {
  margin-bottom: 32px;

  &--container {
  }

  &--content {
    &--island {
      border-radius: size(24);
      background: rgba(256, 256, 256, 0.8);
      padding: size(24);
      position: relative;
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12),
      0 4px 8px 0 rgba(0, 0, 0, 0.14);
      min-height: 140px;

      .profile-preview-overview--avatar {
        &--container {
          flex: 0 0 96px;
          width: 96px;
          height: 96px;
          border-radius: size(96);
          margin-right: size(24);
          position: relative;

          img {
            border-radius: 50%;
          }
        }

        &--item {
          display: block;
          height: 100%;
          width: 100%;
        }
      }

      .user-profile-overview--availability {
        background: white;
        padding: 0;
        min-width: auto;
        position: absolute;
        right: 0;
        bottom: 0;
        border-radius: 28px;
        width: 28px;
        height: 28px;
        border: 3px solid transparent;
      }

      .profile-preview-overview--contact-info {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;

        > div,
        > span {
          margin-bottom: 4px;
        }
      }
    }

    &--top {
      display: flex;
      flex-direction: row;
    }

    &--bottom {
      display: flex;
      flex-direction: row;
      margin-top: 16px;

      .profile {
        &--quick-links {
          display: flex;
          flex-direction: row;
          gap: 8px;
          width: 96px;
          margin-right: 24px;

          &--link {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: size(40);
            width: 40px;
            height: 40px;
            background: white;
            text-decoration: none;
            padding: size(10);
            box-sizing: border-box;
            box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12),
            0 4px 8px 0 rgba(0, 0, 0, 0.14);

            .icon--general {
              width: 24px;
              height: 24px;
            }

            path {
              fill: $black;
            }

            span {
              display: block;

              &.link-text {
                margin-top: size(4);
                text-decoration: none;
              }
            }

            &:hover {
              background: $grey-92;
            }
          }
        }
      }

      .location--wrapper {
        margin-top: 6px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .icon--location {
          margin-right: size(8);
        }

        .country {
        }
      }
    }
  }

  .profile--see-profile-link {
    margin-top: 24px;
  }
}
