@import "../utilities";

.skillpicker {
  position: relative;

  &__inputarea {
    display: flex;
    flex-direction: column;
    gap: size(8);
    position: relative;
    z-index: 2;

    span {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &.suggestions-visible {
      &:after {
        display: block;
        content: "";
        height: 0;
        border-bottom: 1px solid $border-sidebar;
        margin-left: size(16);
        margin-right: size(16);
        z-index: 3;
        margin-top: size(-8);
      }

      input {
        border-bottom-width: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;

        &:focus {
          border: 1px solid $brand-stroke;
          border-bottom-width: 0;
          outline: none;
        }
      }
    }

    input {
      flex-grow: 1;
      max-width: 100%;
      border: 1px solid $brand-stroke;
      border-radius: size(24);
      padding-left: size(16);
      padding-right: size(36);
      padding-top: size(9);
      padding-bottom: size(9);
      background: $white;
      color: $grey-44;
      @include rhythm(16, 22);
      height: size(24);

      &:focus {
        border: 1px solid $brand-stroke;
        outline: none;
      }
    }

    &.suggestions-above {
      &:after {
        display: none;
      }

      span {
        &:before {
          display: block;
          content: "";
          height: 0;
          border-top: 1px solid $border-sidebar;
          margin-left: size(16);
          margin-right: size(16);
          z-index: 3;
          margin-bottom: size(0);
        }
      }

      input {
        border-top-width: 0;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-width: 1px;
        border-bottom-right-radius: size(24);
        border-bottom-left-radius: size(24);
        border-bottom: 1px solid $brand-stroke;

        &:focus {
          border-bottom: 1px solid $brand-stroke;
          border-top-width: 0;
          outline: none;
        }
      }

      + ul.skilllist {
        z-index: 2;
        margin-top: size(0);
        background: $white;
        border: 1px solid $brand-stroke;
        padding: size(14) size(16) size(18);
        @include rhythm(16, 22);
        max-height: size(255);
        overflow: auto;
        position: absolute;
        bottom: size(44);
        width: calc(100% - 34px);
        border-top: 1px solid $brand-stroke;
        border-top-right-radius: size(24);
        border-top-left-radius: size(24);
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-width: 0;
        list-style: none;
        margin-left: 0;
        margin-bottom: 0;
      }
    }

    &--button {
      position: absolute;
      right: 10px;
      bottom: -8px;
      transform: translateY(-50%);
      border: 1px solid $brand-80;
      border-radius: 50%;
      width: size(30);
      height: size(30);
      background: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $white;

      &:hover {
        background: $brand-160;
      }
    }
  }

  ul.skilllist {
    z-index: 1;
    margin-top: size(0);
    background: $white;
    border: 1px solid $brand-stroke;
    border-top: 0;
    border-bottom-right-radius: size(24);
    border-bottom-left-radius: size(24);
    padding: size(14) size(16) size(18);
    @include rhythm(16, 22);
    max-height: size(255);
    overflow: auto;
    position: relative;
    list-style: none;
    margin-left: 0;
    margin-bottom: 0;

    li {
      padding-left: size(16);
      padding-right: size(16);
      color: $grey-44;
      padding-top: size(5);
      padding-bottom: size(5);
      cursor: pointer;

      &:hover {
        background: $brand-160;
      }

      &.active {
        background: $brand-160;
        color: $text-color--neutral-foreground;
      }
    }
  }
}
