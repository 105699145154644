@import "../utilities";

.work-load-meter {
  width: 100%;
  max-width: 500px;
  margin-top: 50px;
  margin-bottom: size(16);

  &--slider {
    height: 10px;
    background: $grey-62;
    position: relative;
    border-radius: size(24);

    &--highlighted {
      background: linear-gradient(90deg, #0000e6 -11.66%, #27a9a3 97.22%);
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      border-radius: size(24) 0 0 size(24);
    }

    &--indicator {
      border-radius: size(99);
      font-size: size(18);
      font-weight: 700;
      font-family: $font-family-lato;
      color: $brand-80;
      width: 71px;
      height: 71px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;
      filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.15));
      margin-left: -36px;

      &--wrapper {
        position: absolute;
        top: -33px;
      }
    }
  }
}