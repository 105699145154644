@import "../utilities";

.add-skill-input--container {
  position: relative;

  .ms-TextField-fieldGroup {
    height: auto;

    input[type="text"] {
      padding: 10px 10px;
    }
  }

  label {
    display: block;
    margin-bottom: size(8);
  }

  #skill-picker {
    padding: size(8) size(100) size(8) size(10);
    height: size(32);
    background: white;
  }


  button {
    position: absolute;
    right: size(10);
    transform: translateY(-50%);
    top: 50%;
    font-weight: 600;

    .ms-Button-flexContainer {
      justify-content: flex-start;
    }
  }
}

.edit-skills--rate-skills {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  //margin-left: -16px;
  //margin-right: -16px;
}

.edit-skills--rate-skills {

  .edit-skills--step-label {
    margin-left: 16px;
    margin-bottom: 24px;
  }
}

.rate-skills {

  &-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    column-gap: size(32);
    row-gap: size(16);

    .level-0 {
      grid-column: 1 / 4;
    }
  }

  &--skills-group {
    //padding-left: 16px;
    //padding-right: 16px;
    //margin-bottom: 16px;
    box-sizing: border-box;
    text-align: center;

    .skill-group--arrow-down {
      margin-bottom: 1rem;
      display: block;
    }

    &--container {
      background: white;
      padding: 16px;
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12),
      0 4px 8px 0 rgba(0, 0, 0, 0.14);
      border-radius: 12px;
      box-sizing: border-box;
      height: 100%;
      text-align: left;

      &.is-over {
        opacity: 0.6;
      }
    }

    h3 {
      margin-bottom: 1rem;
    }

    &.level-0 {
      width: 100%;
      margin-bottom: 0;

      .rate-skills--skills-group--container {
        background: none;
        box-shadow: none;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 0;

        .skills-container {
          margin-top: 1rem;
        }
      }
    }

    &.level-1,
    &.level-2,
    &.level-3 {
      //flex: 0 0 33.333%;
      //width: 33.333%;
      display: flex;
      flex-direction: column;
    }

    .skills-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 5px;
    }

    .drag-skills-here {
      border-radius: 30px;
      background: $brand-160;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 2px 10px 3px;
      font-size: size(16);
      color: $grey-44;

      &.non-rated-skills-count-0 {
        background: $grey-92;
        color: $grey-44;
      }
    }

    .rate-skills--skill {
      border-radius: size(16);
      border: 1px solid $border-divider;
      background: white;
      display: flex;
      word-wrap: break-word;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 2px 10px 3px;
      font-size: size(16);
      max-width: size(225);
      word-break: break-all;
      line-height: 1.1;

      .icon-reorder {
        display: flex;
        cursor: grab;
        margin-top: 2px;
      }

      button.remove-skill {
        background: none;
        border: none;
        margin: 2px 0 0 4px;
        padding-right: 0;
        cursor: pointer;
      }
    }

    &.level-0 {
      .rate-skills--skill {
        border-color: $border-red;
      }
    }

    &.level-1 {
      .rate-skills--skill {
        background: $skill-basic;
      }
    }

    &.level-2 {
      .rate-skills--skill {
        background: $skill-professional;
      }
    }

    &.level-3 {
      .rate-skills--skill {
        background: $skill-expertise;
      }
    }

    &.level-1.language {
      .rate-skills--skill {
        background: $language-basics;
      }
    }

    &.level-2.language {
      .rate-skills--skill {
        background: $language-professional;
      }
    }

    &.level-3.language {
      .rate-skills--skill {
        background: $language-native;
      }
    }
  }
}
