@import "../utilities";

.collapse-element {
  flex-grow: 1;

  &__header {
    cursor: pointer;
    width: 100%;
    min-height: size(62);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    gap: size(16);

    h3 {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      flex-grow: 1;
      gap: size(20);
      justify-content: space-between;
      width: 100%;

      &::after {
        content: "";
        display: block;
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM5.46967 12.0303C5.17678 11.7374 5.17678 11.2626 5.46967 10.9697L9.46967 6.96967C9.76256 6.67678 10.2374 6.67678 10.5303 6.96967L14.5303 10.9697C14.8232 11.2626 14.8232 11.7374 14.5303 12.0303C14.2374 12.3232 13.7626 12.3232 13.4697 12.0303L10 8.56066L6.53033 12.0303C6.23744 12.3232 5.76256 12.3232 5.46967 12.0303Z' fill='%23242424'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        width: size(20);
        height: size(20);
        transform: rotate(180deg);

        .open & {
          transform: rotate(0deg);
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: size(24);
    width: 100%;
    padding-top: size(24);
  }
}
