@import "../utilities";

.help-center--qa-item-full-display {
  flex-basis: 60%;
  width: 60%;
  margin-top: size(-48);
  margin-bottom: size(-136);
  z-index: 5;
  border-left: 1px solid $border-divider;
  background: linear-gradient(to right, #f3f4f7, #f6f7f9, #f9f9fb, #fcfcfd, #ffffff);
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  min-height: 100vh;

  @media all and (min-width: 1024px) {
    //flex-basis: 50%;
    //width: 50%;
    width: 100%;
    max-width: size(515);
  }

  /*&:after {
    display: block;
    content: "";
    background: white;
    position: absolute;
    left: 100%;
    top: 0;
    height: 100%;
    width: 1000px;
    z-index: 4;
  }*/

  &--container {
    position: relative;
    width: 100%;
  }

  .qa-item-full-display {
    &--header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 3rem 1.5rem 0;

      .close-preview--wrapper {
        cursor: pointer;
      }
    }

    &--content {
      padding: 1rem 1.5rem 2rem;

      img {
        max-width: 100%;
      }

      h2 {
        @extend .text-style--Subtitle1;
        margin-bottom: 16px;
      }
    }
  }

  .profile-preview--header {

  }
}
