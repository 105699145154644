@import "../utilities";

.helpdialog {
  display: flex;
  align-items: center;
  gap: size(0);
  flex-flow: row nowrap;

  .ms-Button {
    height: auto;
    padding-top: size(2);
    padding-bottom: 0;
    line-height: 1;
    color: $brand-primary;

    &:hover {
      color: $brand-60;
      background-color: transparent;
    }
  }

  &__dialog {
    &--content {
      display: flex;
      flex-flow: column nowrap;
      gap: size(16);
      margin-bottom: size(24);

      * {
        @extend .text-style--Body1;
      }

      h3 {
        @extend .text-style--Subtitle1;
      }
    }

    .ms-Dialog-title {
      @extend .text-style--Title3;
    }

    .ms-Dialog-main {
      height: auto;
      border-radius: size(18);
      min-width: size(600);
    }

    a {
      text-decoration: underline;
      text-emphasis-color: blue;
    }
  }
}