@import "../utilities";

.input-error {
  color: red;
}

.form--heading--wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .close-edit-popup {
    cursor: pointer;
  }
}

.form--item--actions {
  display: flex;
  gap: size(8);

  .button {
    min-width: size(50);
  }
}

.form--heading {
  font-weight: 600;
  font-size: size(20);
  display: block;
  margin-bottom: size(16);
}

.form--container {
  background-color: $grey-bg;
  padding: size(24);
  border-radius: size(24);

  //&.large {
  //  width: 650px;
  //}
  //
  //&.x-large {
  //  width: 800px;
  //}

  &--header {
    display: flex;
    align-items: center;
    gap: size(16);
  }
}

.form--fields-container {
  &-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    column-gap: size(42);
    row-gap: size(16);
  }

  &-flexcol {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: size(20);
  }

  &.item-row {
    padding-bottom: size(24);
    border-bottom: 1px solid $border-skill;
    margin-bottom: size(24);
    position: relative;

    &:last-child {
      border-bottom: 0;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  &.multivalue {
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 1rem;
  }

  &.flex-column {
    flex-direction: column;
  }

  &.flex-wrap {
    flex-wrap: wrap;
  }

  .form--fields--header {
    flex: 0 1 100%;
    //width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    //padding: size(24) size(24) size(8);

    + .form--fields-container-grid {
      padding-top: size(24);
    }

    .item--preview-data {
      display: flex;
      flex-direction: column;
      //margin-left: size(-24);
      gap: size(8);

      span {
        margin-bottom: 4px;

        &.item-name {
          margin-bottom: 12px;
        }
      }
    }

    &.flex-end {
      justify-content: flex-end;
    }

    &.margin-bottom-minus-20 {
      margin-bottom: -20px;
    }

    .item-name {
      @extend .text-style--Body1;
      @extend .fw-600;
      color: $brand-80;
    }
  }

  &:first-child {
    .form--fields--header {
      padding-top: 0;
    }
  }

  .form--field--wrapper {
    // Stack the label above the field
    display: flex;
    flex-direction: column;

    &.full-width {
      grid-column: 1 / 3;
    }

    &.col-12 {
      grid-column: 1 / 3;

      &:first-child {
        padding-top: 0;
      }
    }

    &.col-6 {
      //@media all and (min-width: 768px) {
      //  grid-column-start: 1;
      //  grid-column-end: 2;
      //}
    }

    &.col-8 {
      @media all and (min-width: 768px) {
        flex: 0 1 50%;
        width: 50%;
      }
      @media all and (min-width: 1024px) {
        flex: 0 1 66.666%;
        width: 66.666%;
      }
    }

    &.col-4 {
      @media all and (min-width: 768px) {
        flex: 0 1 50%;
        width: 50%;
      }
      @media all and (min-width: 1024px) {
        flex: 0 1 33.333%;
        width: 33.333%;
      }
    }

    //&:last-child {
    //  padding-bottom: 1.5rem;
    //}

    label,
    .form-field--label,
    .field-label {
      color: $text-color--secondary;
      font-size: size(14);
      font-weight: 400;
      padding: 0 0 size(5) 0;
    }

    .ms-Checkbox-text {
      //margin-left: 1rem;
    }

    .ms-Checkbox-checkbox {
      margin-right: 0;
    }

    .is-checked .ms-Checkbox-checkbox {
      background: $brand-80;
    }

    .ms-Checkbox-label {
      display: flex;
      gap: size(8);
      align-items: center;
      padding: 0;

      @extend .text-style--MobileSubtitle2;
      color: $brand-10;

      ul {
        list-style-type: disc;
        padding-left: size(22);
        margin-left: 0;
        margin-bottom: 0;
        margin-top: 0;
      }
    }

    .ms-ChoiceField {
      margin-top: 0;
      margin-bottom: size(4);

      .ms-ChoiceField-field {
        display: flex;
        align-items: center;
        flex-flow: row nowrap;
        padding: 0;

        .ms-ChoiceFieldLabel {
          @extend .text-style--Body2;
        }
      }
    }

    .field-with-ai-rewrite {
      &--wrapper {
        position: relative;
      }

      .search-loader--wrapper {
        position: absolute;
        width: 100%;
        top: 28px;
        height: calc(100% - 28px);
        background: white;
        opacity: .7;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &--actions {
        margin-top: size(16);
      }

    }
  }
}

.linkedin {
  &-infolabel {
    display: inline-flex;
    align-items: center;
    gap: size(3);
    color: $brand-80;
    line-height: 24px;
    margin-bottom: size(6);
    cursor: pointer;

    .ms-Button--icon {
      height: 24px;
      margin-bottom: size(-2);
    }
  }

  &__dialog {
    &--content {
      display: flex;
      flex-flow: column nowrap;
      gap: size(24);
      margin-bottom: size(24);

      * {
        @extend .text-style--Body1;
      }
    }

    .ms-Dialog-title {
      @extend .text-style--Title3;
    }

    .ms-Dialog-main {
      height: auto;
      border-radius: size(18);
      max-width: size(801);
      width: 100%;
    }
  }

  &-instructions {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: size(24);

    > li {
      display: flex;
      align-items: flex-start;
      gap: size(10);

      ul {
        list-style-type: disc;
        padding: size(10) 0 0 size(20);
        margin-left: 0;
        margin-bottom: 0;
        margin-top: 0;

        li {
          list-style-type: disc;
          @extend .text-style--Body2;

        }
      }

      span span {
        color: $brand-80;
        font-weight: 700;
      }

      img {
        max-width: size(36);
        width: 100%;
        display: inline;
        vertical-align: middle;
      }

      &::before {
        content: "";
        display: block;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: size(20);
        height: size(20);
        flex-shrink: 0;
      }

      &:nth-child(1) {
        &::before {
          margin-top: 5px;
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM11.5002 5.25001V14.25C11.5002 14.6642 11.1644 15 10.7502 15C10.336 15 10.0002 14.6642 10.0002 14.25V8.10031C9.45204 8.6878 8.76546 9.20216 7.98737 9.46152C7.59442 9.59251 7.16968 9.38014 7.03869 8.98718C6.90771 8.59422 7.12008 8.16949 7.51303 8.0385C8.08472 7.84794 8.66166 7.39534 9.13602 6.80936C9.61092 6.22272 9.91911 5.58119 10.0148 5.10292C10.0904 4.72482 10.4404 4.46567 10.8241 4.50366C11.2078 4.54166 11.5002 4.86442 11.5002 5.25001Z' fill='%2308757A'/%3E%3C/svg%3E%0A");
        }
      }

      &:nth-child(2) {
        &::before {
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM8.35127 6.95093C8.35427 6.94713 8.36057 6.93927 8.37008 6.92792C8.38915 6.90515 8.42082 6.86874 8.46442 6.82317C8.55224 6.73138 8.68477 6.6063 8.85645 6.48129C9.20265 6.22919 9.67528 6 10.2495 6C11.2159 6 11.9993 6.78342 11.9995 7.75012C11.9996 8.2843 11.8051 8.61236 11.472 8.90668C11.1523 9.18911 10.7459 9.40901 10.232 9.68716C10.1224 9.74644 10.008 9.80836 9.88848 9.8741C9.25503 10.2225 8.51848 10.6625 7.95183 11.3519C7.3644 12.0666 7 12.9963 7 14.25C7 14.6642 7.33579 15 7.75 15H12.75C13.1642 15 13.5 14.6642 13.5 14.25C13.5 13.8358 13.1642 13.5 12.75 13.5H8.56545C8.66401 12.9798 8.86537 12.6028 9.11065 12.3043C9.48149 11.8531 9.9949 11.5275 10.6114 11.1884C10.7028 11.1381 10.7984 11.0867 10.8969 11.0337C11.412 10.7567 12.0037 10.4384 12.4651 10.0308C13.0693 9.49702 13.4997 8.7782 13.4995 7.74988C13.4992 5.95516 12.0445 4.5 10.2495 4.5C9.26171 4.5 8.48555 4.89581 7.97346 5.26871C7.71599 5.4562 7.517 5.64362 7.38058 5.78621C7.31206 5.85783 7.25843 5.91907 7.22024 5.96466C7.19725 5.9921 7.17465 6.01991 7.15306 6.04847L7.15222 6.04959L7.15147 6.05059C6.90326 6.3822 6.97088 6.85224 7.3025 7.10044C7.63289 7.34773 8.10071 7.28151 8.34962 6.95303L8.35127 6.95093ZM8.34962 6.95303L8.35201 6.94987L8.34962 6.95303Z' fill='%2308757A'/%3E%3C/svg%3E%0A");
        }
      }

      &:nth-child(3) {
        &::before {
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM8.5 10C8.5 9.58579 8.83579 9.25 9.25 9.25H10.1237C11.2327 9.25 12 8.45328 12 7.625C12 6.79672 11.2327 6 10.1237 6C9.49077 6 8.97652 6.22876 8.60961 6.47024C8.42694 6.59046 8.28662 6.71022 8.19432 6.79735C8.14843 6.84067 8.11518 6.87519 8.09529 6.89662C8.08537 6.90732 8.07884 6.91468 8.07579 6.91816L8.07424 6.91994C7.80924 7.23544 7.33882 7.2782 7.02122 7.0148C6.70239 6.75037 6.65828 6.27755 6.9227 5.95872L6.92352 5.95774L6.92443 5.95665L6.92653 5.95414L6.93187 5.94782L6.94713 5.93017C6.95931 5.91626 6.97554 5.8981 6.99575 5.87633C7.03612 5.83282 7.09262 5.77457 7.16466 5.70656C7.30824 5.57103 7.51656 5.39391 7.78496 5.21726C8.3202 4.86499 9.11781 4.5 10.1237 4.5C11.9156 4.5 13.5 5.82993 13.5 7.625C13.5 8.60014 13.0325 9.43802 12.3181 10C13.0325 10.562 13.5 11.3999 13.5 12.375C13.5 14.1701 11.9156 15.5 10.1237 15.5C9.11781 15.5 8.3202 15.135 7.78496 14.7827C7.51656 14.6061 7.30824 14.429 7.16466 14.2934C7.09262 14.2254 7.03612 14.1672 6.99575 14.1237C6.97554 14.1019 6.95931 14.0837 6.94713 14.0698L6.93187 14.0522L6.92653 14.0459L6.92443 14.0433L6.92352 14.0423L6.9227 14.0413C6.65828 13.7224 6.70239 13.2496 7.02122 12.9852C7.33882 12.7218 7.80923 12.7646 8.07423 13.08L8.07491 13.0809L8.07579 13.0818C8.07884 13.0853 8.08537 13.0927 8.09529 13.1034C8.11518 13.1248 8.14843 13.1593 8.19432 13.2027C8.28662 13.2898 8.42694 13.4095 8.60961 13.5298C8.97652 13.7712 9.49077 14 10.1237 14C11.2327 14 12 13.2033 12 12.375C12 11.5467 11.2327 10.75 10.1237 10.75H9.25C8.83579 10.75 8.5 10.4142 8.5 10ZM8.07423 13.08L8.07579 13.0818L8.07423 13.08Z' fill='%2308757A'/%3E%3C/svg%3E%0A");
        }
      }

      &:nth-child(4) {
        &::before {
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM12.5 5.68811C12.5 4.59119 11.0703 4.17002 10.4757 5.09177L6.31876 11.535C5.91092 12.1671 6.36474 13 7.11704 13H11V14.25C11 14.6642 11.3358 15 11.75 15C12.1642 15 12.5 14.6642 12.5 14.25V13H13.25C13.6642 13 14 12.6642 14 12.25C14 11.8358 13.6642 11.5 13.25 11.5H12.5V5.68811ZM11 7.04594V11.5H8.12641L11 7.04594Z' fill='%2308757A'/%3E%3C/svg%3E%0A");
        }
      }

      &:nth-child(5) {
        &::before {
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM8.75 5C8.35637 5 8.02971 5.30432 8.00188 5.69697L7.75188 9.22375C7.73663 9.43887 7.81472 9.65015 7.96622 9.80364C8.11771 9.95712 8.32793 10.038 8.54322 10.0255L8.54661 10.0253L8.55827 10.0247L8.60478 10.0222C8.64554 10.0201 8.7049 10.0171 8.77829 10.0138C8.92531 10.0072 9.12736 9.99929 9.34802 9.99403C9.80972 9.98301 10.2839 9.98586 10.532 10.0197C11.6251 10.174 12.3865 11.185 12.2329 12.2784C12.0792 13.3722 11.0678 14.1343 9.974 13.9806C9.27532 13.8824 8.71131 13.4347 8.43492 12.8357C8.26139 12.4596 7.81582 12.2953 7.43971 12.4689C7.06359 12.6424 6.89937 13.088 7.07289 13.4641C7.55394 14.5067 8.54041 15.2938 9.76524 15.466C11.6794 15.735 13.4493 14.4013 13.7183 12.4871C13.9873 10.5729 12.6536 8.8031 10.7394 8.53408L10.7369 8.53373C10.3517 8.48092 9.75905 8.48379 9.31224 8.49445L9.30733 8.49457L9.44872 6.5H12.2505C12.6647 6.5 13.0005 6.16421 13.0005 5.75C13.0005 5.33579 12.6647 5 12.2505 5H8.75Z' fill='%2308757A'/%3E%3C/svg%3E%0A");
        }
      }
    }
  }

  &-input {
    display: flex;
    align-items: flex-end;
    gap: size(24);
    flex-flow: row nowrap;

    .ms-TextField {
      flex-grow: 1;
    }

    .linkedin-icon {
      &:after {
        margin-top: size(1);
        content: "";
        display: block;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: size(32);
        height: size(32);
        background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.55556 32H28.4444C30.4081 32 32 30.4081 32 28.4444V3.55556C32 1.59188 30.4081 0 28.4444 0H3.55556C1.59188 0 0 1.59188 0 3.55556V28.4444C0 30.4081 1.59188 32 3.55556 32Z' fill='%23007EBB'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M27.5556 27.5556H22.807V19.4676C22.807 17.2501 21.9644 16.0109 20.2092 16.0109C18.2998 16.0109 17.3023 17.3005 17.3023 19.4676V27.5556H12.7259V12.1482H17.3023V14.2235C17.3023 14.2235 18.6783 11.6774 21.9478 11.6774C25.2159 11.6774 27.5556 13.6731 27.5556 17.8006V27.5556ZM7.26639 10.1307C5.70759 10.1307 4.44446 8.85764 4.44446 7.28757C4.44446 5.71751 5.70759 4.44446 7.26639 4.44446C8.82519 4.44446 10.0876 5.71751 10.0876 7.28757C10.0876 8.85764 8.82519 10.1307 7.26639 10.1307ZM4.90337 27.5556H9.6753V12.1482H4.90337V27.5556Z' fill='white'/%3E%3C/svg%3E%0A");
      }
    }

    + .input-error {
      margin-top: size(-20);
      margin-bottom: size(32);
    }
  }

  .profile-picture-edit-form {
    padding: 0;

    .preview--container {
      background: linear-gradient(96deg, #a3cffd 4.48%, #b4fbfa 90.68%);
      padding: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: size(24) size(24) 0 0;

      .user-profile-overview--avatar--item {
        width: 120px;
        height: 120px;
      }
    }

    &--buttons--container {
      padding: 23px 32px;
      display: flex;
      align-items: center;
      justify-content: center;

      label.button--outline--small {
        display: flex;
        align-items: center;
        cursor: pointer;

        .icon--upload {
          width: 20px;
          height: 20px;
          margin-right: 6px;
        }
      }
    }
  }

  .form--buttons--container {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    margin-top: 2rem;

    .icon--delete {
      color: black;
      width: 16px;
      height: 16px;
      margin: 0 6px 0 0;

      svg {
        width: 16px;
        height: 16px;

        path {
          fill: white;
        }
      }
    }

    &.my-interests--actions {
      margin-top: 2rem;
    }
  }

  .form-field--label {
    color: $brand-80;
    font-family: $font-family-lato;
    @include rhythm(18, 20);
    font-weight: 600;
  }

  .ms-Suggestions-title {
    color: $brand-80;
    font-weight: 600;
  }

  .ms-Suggestions-container {
    .ms-Suggestions-item {
      button {
        &:hover,
        &[aria-selected="true"] {
          background: $brand-160;
        }
      }
    }
  }

  .react-datepicker__input-container {
    input {
      width: 100%;
      padding: 5px 7px 6px;
      box-sizing: border-box;
      border: 1px solid $brand-10;
      border-radius: 2px;
    }
  }
}

.edit-dialog {
  background: white;
  left: 50%;
  width: 100%;
  max-width: size(800);
  max-height: 80%;
  overflow-y: auto;
  padding: size(24);
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: size(24);

  &--sm {
    max-width: size(600);
  }

  &--lg {
    max-width: size(950);
  }

  .hidden-input {
    display: none;
  }

  &--root {
    background: rgba(0, 0, 0, 0.5);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
  }

  &--header {
    margin-left: size(-24);
    margin-right: size(-24);
    padding: 0 size(24);
    border-bottom: 1px solid $border-divider;
    margin-bottom: size(32);
  }

  &--buttons {
    display: flex;
    flex-flow: row nowrap;
    gap: size(16);
    align-items: center;
    justify-content: flex-end;
  }

  &--checkbox {
    align-items: center;

    .ms-Checkbox-text {
      @extend .text-style--Subtitle2;
      color: $text-color--neutral-foreground;
      font-family: $font-family-lato;
      margin-left: 0;
    }
  }

  &--profilepic {
    position: relative;
    min-height: size(270);

    .reactEasyCrop_Container {
      border-radius: size(24) size(24) 0 0;
    }

    &-empty {
      position: relative;
      min-height: size(270);
      background: linear-gradient(95.97deg, #A3CFFD 4.48%, #B4FBFA 90.68%);
      border-radius: size(24) size(24) 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-upload {
      padding: size(24) size(16);
      background: $grey-bg;
      border-radius: 0 0 size(24) size(24);
      display: flex;
      align-items: center;
      gap: size(24);
      min-height: size(42);

      .controls {
        flex-grow: 1;
      }

      .ms-Label {
        line-height: 1;
        font-weight: 700;
        text-align: center;
      }
    }

  }

  .ms-Slider {
    max-width: size(520);
  }

  .ms-Slider-slideBox {
    padding-left: 0;

    .ms-Slider-thumb {
      width: 18px;
      height: 18px;
      border-radius: 15px;
      border: 3px solid $white;
      position: relative;
      z-index: 1;

      border-width: 2px;
      border-style: solid;
      border-color: $white;
      border-radius: 10px;
      box-sizing: border-box;
      background: linear-gradient(180deg, #0DBAB3 0%, #1860BD 100%);
      display: block;
      width: 16px;
      height: 16px;
      position: absolute;
      top: -6px;
      transform: translateX(-50%);

      &:after {
        display: block;
        //content: "";
        width: 19px;
        height: 19px;
        z-index: 0;
        border-radius: 50%;
        background: transparent;
        position: absolute;
        top: -4px;
        left: -4px;
        border: 1px solid #d1d1d1;
      }
    }

    .ms-Slider-active {
      background: linear-gradient(180deg, #0DBAB3 0%, #1860BD 100%);
    }
  }

  .new-item--actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: size(8);
    flex-grow: 1;
    padding: size(8) 0 size(16);
  }

}

.side-by-side-buttons--wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: size(8);
}

.icon--linkedin {
  width: 24px;
  height: 24px;
}

.linkedin-dialog-heading {
  .form--heading {
    position: relative;
    padding: size(8) size(16);

    &:before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: size(30);
      border: 2px solid transparent; /*2*/
      background: linear-gradient(45deg, #0430ec, #0ebbb3) border-box; /*3*/
      -webkit-mask: /*4*/
        linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor; /*5'*/
      mask-composite: exclude; /*5*/
    }
  }
}

.linkedin-edit-form {
  .form--container--header {
    align-items: flex-start;
  }

  .logo-wrapper {
    width: 62px;
    height: 62px;
    flex: 0 0 62px;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon--general {
      width: 24px;
      height: 24px;
    }
  }

  .why-linkedin-description {
    display: flex;
    flex-direction: row;

    .title {
      flex: 0 0 auto;
      margin-right: size(16);
    }

    .description {
      flex: 1;
    }
  }
}

.linkedin-fields {
  &--description {
    padding-left: size(32);
  }

  &--trigger {
    cursor: pointer;

    .icon--general {
      transition: all .5s;

      &.chevron-up {
        svg {
          transform: rotate(180deg);
        }
      }

    }
  }

  &--fields-list {
    transition: all 1s;

    &--items {
      list-style: disc;
      margin-left: size(24);
      margin-top: size(8);
      margin-bottom: size(16);

      li {
        font-size: size(16);
      }

    }
  }
}
