@import "../utilities";

.user-profile-page {
  &--background {
    &--container {
      height: 340px;
      width: 100%;
    }

    &--image {
      height: 340px;
      background-position: center;
      position: relative;
      background-size: cover;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(256, 256, 256, 0.5);
      }
    }
  }

  &--container {
    @include content-container;
    margin-top: -342px;
    padding: size(48) size(24);

    &.preview {
      margin-top: 0;
      padding: 0;

      .field-group--container .field-group--item.item-listing > div.item-description {
        margin-top: 0;
      }
    }
  }

  &--overview {
    .preview & {
      padding: size(16) size(24) 0;
    }

    h1 {
      display: block;
      text-align: center;
      margin-bottom: size(24);
      margin-top: 0;
    }
  }
}

.user-profile-tab--heading--container {
  display: flex;
  align-items: center;
  gap: size(10);

  .icon--general {
    width: 33px;
    height: 33px;
  }
}

h2.user-profile-tab--heading {

}

.field-group--container {
  border-radius: 1.5rem;
  border: 1px solid #d1d1d1;
  padding: size(24);
  margin-bottom: size(40);

  &-separator {
    border-top: 1px solid $border-divider;
    flex-grow: 1;
    width: 100%;
    grid-column: 1/4;
  }

  .display-mode-preview & {
    padding: 0;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $border-divider;
    padding-bottom: size(16);

    &:last-child {
      border-bottom: none;
    }
  }

  .field-group--header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .field-group--fields {
    display: grid;
    width: 100%;
    column-gap: size(24);
    row-gap: size(32);
    grid-template-columns: 1fr;

    @media all and (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media all and (min-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
    }

    .display-mode-preview & {
      margin-top: 0;
      grid-template-columns: 1fr;

      @media all and (min-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
      }

      @media all and (min-width: 860px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media all and (min-width: 1024px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    + .empty-content-placeholder {
      margin-top: size(40);
    }
  }

  .field-placeholder {

    width: 100%;
    height: size(27);
    margin-top: 2px;

    background: $grey-94;
    border-radius: size(8);
  }

  .field-wrapper {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: size(5);

    //@media all and (min-width: 768px) {
    //  flex: 0 1 50%;
    //  width: 50%;
    //}
    //
    //@media all and (min-width: 1024px) {
    //  flex: 0 1 33.333%;
    //  width: 33.333%;
    //  .display-mode-preview & {
    //    flex: 0 1 50%;
    //    width: 50%;
    //  }
    //}

    &.full-width {

      @media all and (min-width: 860px) {
        grid-column: 1 / 4;
      }
    }

    .field-label {
      @extend .text-style--Body1;
      color: $brand-80;
      font-weight: 600;

      &-empty {
        color: $grey-46;
      }

      .display-mode-preview & {
        @extend .text-style--Subtitle2;
      }
    }

    .field-value {
      text-align: left;
      color: $text-color--brand-10;
      @include rhythm(18, 27);

      .display-mode-preview & {
        @extend .text-style--Subtitle2;
      }
    }
  }

  .field-group--item {
    padding: 1.5rem 0;
    border-bottom: 1px solid #e0e0e0;

    &.my-interests--container {
      padding: 0;
      border-bottom: none;

      .display-mode-preview & {
        padding: 0;
      }
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }

    .display-mode-preview & {
      border-bottom: none;
      padding: 0.75rem 0;

      .text-style--field-display-label {
        font-size: size(16);
      }
    }

    &.item-listing {
      > div {
        margin-bottom: size(8);
        font-family: $font-family-lato;

        &.item-description {
          margin-top: size(24);
        }
      }

      .display-mode-preview & {
        .text-style--Body1 {
          font-size: size(16);
        }
      }
    }

    &--wrapper {
      text-align: left;

      .field-label {
        @extend .text-style--Body1;
        color: $brand-80;
        margin-bottom: size(12);
        font-weight: 600;

        .display-mode-preview & {
          font-size: size(16);
        }
      }
    }
  }

  .skill-groups {
    padding-top: size(24);
  }

  .skill-group {
    padding: size(16) 0;

    &:first-child {
      padding-top: 0;
    }

    &--label {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: size(16);

      h4 {
        color: $brand-80;
        font-weight: 600;
        font-size: size(18);

        .display-mode-preview & {
          font-size: size(16);
        }
      }

      &-noskills {
        h4 {
          color: $grey-46;

        }

        path {
          fill: $grey-46;
        }
      }

      &-norated {
        padding-left: size(16);
        margin-bottom: 0;
      }

      .skill-rating {
        display: flex;
        flex-direction: row;
        margin-right: size(4);
        margin-top: size(3);

        .icon-star {
          margin-right: 4px;
        }
      }

      h4 {
      }
    }

    &--items {
      display: flex;
      gap: size(8);
      flex-wrap: wrap;

      .skill-item {
        padding: size(4) size(16) size(6);
        color: $text-color--brand-10;
        border-radius: size(30);
        border: 1px solid $border-skill;
        font-weight: 400;
        font-size: size(16);

        .display-mode-preview & {
          font-size: size(14);
        }

        &.special-expertise {
          background: $skill-expertise;
        }

        &.professional {
          background: $skill-professional;
        }

        &.basic {
          background: $skill-basic;
        }

        &.language-native {
          background: $language-native;
        }

        &.language-professional {
          background: $language-professional;
        }

        &.language-basics {
          background: $language-basics;
        }

        &.non-rated {
          border-color: $border-red;
          background: $grey-92;
          color: $brand-10;
        }
      }
    }
  }

  .non-ratedskills {

    &--wrapper {
      padding: size(16);
      background: $grey-bg;
      border-radius: size(24);
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: space-between;
      gap: size(16);

      @media all and (min-width: 768px) {
        flex-flow: row nowrap;
        gap: size(32)
      }
      @media all and (min-width: 1024px) {
        gap: size(96)
      }
    }
  }

  .language-skills--container {
    display: flex;
    flex-direction: row;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    margin-top: size(24);

    .display-mode-preview & {
      flex-direction: column;
    }

    .skill-group {
      flex-basis: 33.333%;
      width: 33.333%;
      padding: 0 size(24);
      box-sizing: border-box;
      border-left: 1px solid $border-divider;

      .display-mode-preview & {
        flex-basis: 100%;
        width: 100%;
        border-left: none;
        padding: 1rem 1.5rem;
      }

      &:first-child {
        border-left: none;
      }
    }
  }

  .my-interests--container {
    .my-interests--list {
      &--wrapper {
      }

      &--items {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 5px;
      }

      &--item {
        border-radius: 30px;
        //border: 1px solid $brand-10;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: size(6) size(16) size(8);
        @include rhythm(14, 14);
        position: relative;
        color: $brand-10;

        &:before {
          content: "";
          position: absolute;
          inset: 0;
          border-radius: size(30);
          border: 1px solid transparent; /*2*/
          background: linear-gradient(45deg, #0430ec, #0ebbb3) border-box; /*3*/
          -webkit-mask: /*4*/
            linear-gradient(#fff 0 0) padding-box,
            linear-gradient(#fff 0 0);
          -webkit-mask-composite: xor; /*5'*/
          mask-composite: exclude; /*5*/
        }
      }
    }
  }

  .empty-content-placeholder {
    padding: size(16);
    background: $grey-bg;
    border-radius: size(16);
    margin-top: size(16);
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;

    &--description {
      max-width: size(640);
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.empty-skill-placeholder {
  padding: size(16);
  background: $grey-bg;
  border-radius: size(16);
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  gap: size(16);


  @media all and (min-width: 768px) {
    flex-flow: row nowrap;
    gap: size(32)
  }
  @media all and (min-width: 1024px) {
    gap: size(96)
  }

}

.empty-interest-placeholder {
  padding: size(16);
  background: $grey-bg;
  border-radius: size(16);
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
  gap: size(16);


  @media all and (min-width: 768px) {
    flex-flow: row nowrap;
    gap: size(32)
  }
  @media all and (min-width: 1024px) {
    gap: size(96)
  }

}

.user-profile-details {
  border-radius: 0 0 size(24) size(24);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12),
  0 8px 16px 0 rgba(0, 0, 0, 0.14);
  padding: size(24) size(40);
  background: white;
  position: relative;

  .connect-linkedin {
    position: absolute;
    top: 36px;
    right: 68px;
  }

  &.display-mode-preview {
    background: none;

    .profile-tabs-container {

      .ms-Pivot {
        padding-left: size(16);
      }
    }
  }

  .profile-tabs-container {

    .ms-Pivot {
      display: flex;
      align-items: center;
      gap: size(0);
      margin-bottom: size(40);
      margin-left: size(14);
      margin-right: size(14);
      flex-flow: row wrap;

      .ms-Pivot-link {
        font-family: $font-family-lato;

        @extend .text-style--Body1;

        padding: size(12);
        height: auto;
        margin-right: 0;
        user-select: none;

        .ms-Pivot-text {

          color: $grey-32;
          @extend .text-style--Body1;
        }

        &.is-selected {

          &::before {
            background-color: $brand-80;
          }

          .ms-Pivot-text {
            font-weight: 600;
            color: $text-color--neutral-foreground;
          }
        }
      }
    }

    &.display-mode-preview {

      .ms-Pivot {
        margin-bottom: size(24);
      }
    }
  }

  &.linkedin-disconnected {
    .ms-Pivot {
      padding-right: 140px;
    }
  }

  .profile-tab--content {
    //padding: size(40);
  }

  &.display-mode-preview {
    border-radius: 0;
    box-shadow: none;
    padding: 0 0 size(32) 0;

    .profile-tabs-container {
      margin-bottom: size(16);
    }

    .profile-tab--content {
      padding: 0;

      .preview & {
        padding: 0 size(24);
      }
    }
  }
}

.myprofile {
  &-welcome {
    &__modal {
      max-width: size(1150);
      padding: size(40);
      border-radius: size(24);

      &--header {
        display: flex;
        align-items: center;
        flex-flow: row nowrap;
        justify-content: space-between;
      }

      &--columns {
        display: grid;
        grid-template-columns: 0.656fr 0.344fr;
        grid-column-gap: size(24);
      }

      &--textcols {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: size(24);
      }

      .ms-Button-flexContainer {
        gap: size(5);
      }
    }
  }
}

.delete-itemrow {
  position: absolute;
  top: -2px;
  right: -2px;
  z-index: 2;
  background: white;
  padding: size(24);
  box-shadow: 0px 32px 64px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.2);
  border-radius: 24px;


}
