@import "../utilities";

.fill-rate-info {
  position: absolute;
  bottom: 0;
  right: 0;

  .button-style--outline {
    padding: 0;
    width: 31px;
    height: 31px;
    justify-content: center;
    align-items: center;
  }

  &__modal {
    max-width: size(1150);
    padding: size(40);
    border-radius: size(24);

    &--header {
      display: flex;
      align-items: center;
      flex-flow: row nowrap;
      justify-content: space-between;
    }

    &--columns {
      display: grid;
      grid-template-columns: repeat(1fr);
      grid-column-gap: size(24);
    }

    &--col {
      display: flex;
      flex-direction: column;
      //align-items: center;
      padding: size(16);
      gap: size(16);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.14), 0 0 0 rgba(0, 0, 0, 0.12);
      border-radius: size(24);
    }

    &--textcols {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: size(24);
      padding: 0 size(8);
    }
  }

  &__fields-columns {
    display: flex;
    flex-direction: row;
    margin: size(24) 0 0;
    gap: size(40);

    .field-group {
      margin-bottom: size(24);

      ul {
        list-style: disc;

        li {
          margin-left: size(24);
        }
      }
    }
  }

  &__dialog {
    &--content {
      display: flex;
      flex-flow: column nowrap;
      gap: size(24);
      margin-bottom: size(24);

      * {
        @extend .text-style--Body1;
      }

      .tour-subtitle {
        font-weight: 700;
        color: $brand-80;
        margin-bottom: size(0);

        + p {
          margin-top: size(-16);
        }
      }

      ul {
        margin-left: size(16);
        display: flex;
        flex-direction: column;
        gap: size(8);
        margin-top: size(-10);
        margin-bottom: 0;
        list-style: none;

        li {
          display: flex;
          align-items: center;
          gap: size(8);
          flex-flow: row nowrap;

          &:before {
            display: block;
            content: "";
            background-size: contain;
            width: size(20);
            height: size(20);
            background-repeat: no-repeat;
            background-position: center;
          }

          &:nth-child(1) {
            &:before {
              background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.0001 6.49997C11.0001 6.24746 10.8118 6.03457 10.5611 6.00372C10.3105 5.97287 10.0762 6.13373 10.015 6.37871C9.91244 6.78889 9.64181 7.29013 9.28755 7.76248C8.93439 8.23336 8.53619 8.62496 8.2227 8.83395C7.99294 8.98713 7.93085 9.29756 8.08403 9.52732C8.2372 9.75709 8.54764 9.81918 8.7774 9.666C9.18228 9.39608 9.62181 8.96065 10.0001 8.47675V13.5C10.0001 13.7761 10.2239 14 10.5001 14C10.7762 14 11.0001 13.7761 11.0001 13.5V6.49997ZM10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10Z' fill='%23242424'/%3E%3C/svg%3E%0A");
            }
          }

          &:nth-child(2) {
            &:before {
              background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10ZM10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM8.40025 7.3017C8.23437 7.52097 7.9223 7.56524 7.70192 7.40029C7.48084 7.23482 7.43576 6.92147 7.60123 6.70039L7.60231 6.69895C7.61863 6.67754 7.63556 6.65658 7.65285 6.63595C7.68213 6.60099 7.72357 6.55365 7.77672 6.4981C7.88261 6.38741 8.03738 6.24163 8.23763 6.09581C8.63625 5.80554 9.23694 5.5 9.99959 5.5C11.3804 5.5 12.4994 6.61935 12.4996 7.99992C12.4997 8.78654 12.1743 9.34822 11.7208 9.78284C11.3662 10.1227 10.9117 10.4015 10.499 10.6546C10.4191 10.7036 10.3407 10.7516 10.265 10.799C9.77045 11.1081 9.33701 11.4127 9.02036 11.8217C8.78704 12.1231 8.6059 12.4965 8.53386 13H12C12.2761 13 12.5 13.2239 12.5 13.5C12.5 13.7761 12.2761 14 12 14H8C7.72386 14 7.5 13.7761 7.5 13.5C7.5 12.5184 7.7843 11.7848 8.22963 11.2095C8.66297 10.6498 9.2295 10.2669 9.73494 9.95101C9.82749 9.89316 9.91707 9.838 10.0036 9.78471C10.4156 9.53105 10.7589 9.31961 11.0288 9.06091C11.3253 8.77678 11.4997 8.46346 11.4996 8.00008C11.4994 7.17152 10.8279 6.5 9.99959 6.5C9.51265 6.5 9.11431 6.69446 8.82629 6.90419C8.68323 7.00837 8.57278 7.11259 8.49928 7.1894C8.46274 7.2276 8.43594 7.25838 8.41941 7.27812C8.41116 7.28796 8.40552 7.295 8.40255 7.29875L8.40025 7.3017Z' fill='%23242424'/%3E%3C/svg%3E%0A");
            }
          }

          &:nth-child(3) {
            &:before {
              background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10ZM10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM7.42748 7.38207C7.63783 7.55964 7.95197 7.5337 8.1304 7.32451L8.13315 7.32139C8.13659 7.31752 8.14307 7.31036 8.15252 7.30035C8.17145 7.28031 8.20209 7.24909 8.24385 7.21036C8.32771 7.13259 8.4544 7.02642 8.61918 6.91987C8.94965 6.70619 9.4186 6.5 9.99892 6.5C11.0233 6.5 11.75 7.22552 11.75 8C11.75 8.77448 11.0233 9.5 9.99892 9.5H9.50001C9.22387 9.5 9.00001 9.72386 9.00001 10C9.00001 10.2761 9.22387 10.5 9.50001 10.5H9.99892C11.0233 10.5 11.75 11.2255 11.75 12C11.75 12.7745 11.0233 13.5 9.99892 13.5C9.4186 13.5 8.94965 13.2938 8.61918 13.0801C8.4544 12.9736 8.32771 12.8674 8.24385 12.7896C8.20209 12.7509 8.17145 12.7197 8.15252 12.6996C8.14307 12.6896 8.13659 12.6825 8.13315 12.6786L8.1304 12.6755C7.95197 12.4663 7.63783 12.4404 7.42748 12.6179C7.21647 12.7961 7.18982 13.1115 7.36794 13.3225L7.36914 13.3239L7.37066 13.3257L7.37466 13.3304L7.38652 13.3438C7.39613 13.3546 7.40915 13.369 7.42553 13.3863C7.45826 13.4209 7.50454 13.4678 7.56387 13.5229C7.68218 13.6326 7.85433 13.7764 8.07619 13.9199C8.51899 14.2062 9.17449 14.5 9.99892 14.5C11.4611 14.5 12.75 13.4347 12.75 12C12.75 11.1603 12.3085 10.4471 11.649 10C12.3085 9.55289 12.75 8.83971 12.75 8C12.75 6.56534 11.4611 5.5 9.99892 5.5C9.17449 5.5 8.51899 5.79381 8.07619 6.08013C7.85433 6.22358 7.68218 6.36741 7.56387 6.47714C7.50454 6.53216 7.45826 6.57906 7.42553 6.61371C7.40915 6.63105 7.39613 6.64537 7.38652 6.65615L7.37466 6.66963L7.37066 6.67428L7.36914 6.67606L7.36821 6.67716C7.19008 6.88817 7.21647 7.20394 7.42748 7.38207Z' fill='%23242424'/%3E%3C/svg%3E%0A");
            }
          }
        }
      }
    }

    .ms-Dialog-title {
      @extend .text-style--Title3;
    }

    .ms-Dialog-main {
      height: auto;
      border-radius: size(18);
      min-width: size(600);
    }
  }
}
