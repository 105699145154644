@import "../utilities";

.form--field--wrapper {
  .field-with-ai-rewrite {
    &--wrapper {
      position: relative;
    }

    .search-loader--wrapper {
      position: absolute;
      width: 100%;
      top: 28px;
      height: calc(100% - 28px);
      background: white;
      opacity: .7;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--actions {
      margin-top: size(16);
    }

  }
}

.add-interest-input {
  &--wrapper {
    position: relative;

    button {
      position: absolute;
      right: size(10);
      transform: translateY(-50%);
      top: 50%;
      font-weight: 600;

      .ms-Button-flexContainer {
        justify-content: flex-start;
      }
    }
  }

  &--container {
    position: relative;

    .ms-TextField-fieldGroup {
      height: auto;

      input[type="text"] {
        padding: 10px 10px;
      }
    }

    label {
      font-weight: 600;
      margin: 8px 0 4px;
      display: block;
    }

    #interests-picker {
      padding: size(8) size(100) size(8) size(10);
      height: size(32);
      background: white;
    }
  }
}

.my-interests--list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;

  .my-interests--list--item {
    border-radius: 30px;
    border: 1px solid $brand-10;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2px 10px 3px;
    font-weight: 600;

    button.remove-item {
      background: none;
      border: none;
      margin: 2px 0 0 4px;
      padding-right: 0;
      cursor: pointer;

      .icon-delete {
        width: 12px;
        height: 13px;
        display: flex;
        align-items: center;
      }
    }
  }
}
